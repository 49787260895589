<template>
  <div class="flex justify-center relative mb-[30vw] md:mb-[9vw] md:w-[31.75vw]"
       :data-character="character"
       ref="characterRef"
  >
    <NuxtImg format="auto"
             :src="`https://cdn-ttf.o7web.com/assets/public/img/characters/social/${props.character}.png`"
             alt=""
             class="h-[75vw] md:h-[29.75vw] relative z-10 pointer-events-none"
             :class="[props.character === 'tom-waving' ? 'mr-[8vw] md:mr-[3vw]' : '']"
             sizes="xs:75vw sm:75vw md:40vw lg:40vw xl:40vw xxl:40vw"
             loading="lazy"
    />
    <div ref="circle"
         data-social
         class="w-[81vw] md:w-[31.75vw] h-[81vw] md:h-[31.75vw] rounded-full top-[10vw] md:top-[4vw] absolute m-auto left-0 right-0 shadow-m opacity-0"
         :class="`bg-${background}`"
    >
      <CardSocialButton v-for="(item, index) in social"
                        :key="index"
                        :icon="item.icon"
                        :href="links[item.name]"
                        :label="item.label"
                        :background-color="iconColor"
                        class="text-white"
                        :class="{
                          'bottom-[7.5vw] md:bottom-[1.875vw]': index === 0 || index === 3,
                          'top-[72.5vw] md:top-[28.43vw]': index === 1 || index === 2,
                          'left-[1vw] md:left-[0.25vw]': index === 0,
                          'left-[20.25vw] md:left-[8vw]': index === 1,
                          'right-[20.25vw] md:right-[8vw]': index === 2,
                          'right-[1vw] md:right-[0.25vw]': index === 3
                        }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { animate, inView, spring, stagger } from 'motion';
import { onMounted, ref } from 'vue';

type CardSocialCharacterProps = {
  character: string;
  background: string;
  iconColor: string;
  delay?: number;
  links: {
    tiktok: string;
    facebook: string;
    youtube: string;
    instagram: string;
  };
};

const props = withDefaults(defineProps<CardSocialCharacterProps>(), {
  delay: 0
});

type SocialList = {
  name: 'tiktok' | 'facebook' | 'youtube' | 'instagram';
  icon: string;
  label: string;
}[];

const social: SocialList = [
  {
    name: 'tiktok',
    label: 'TikTok',
    icon: 'TikTok'
  },
  {
    name: 'youtube',
    label: 'YouTube',
    icon: 'Youtube'
  },
  {
    name: 'instagram',
    label: 'Instagram',
    icon: 'Instagram'
  },
  {
    name: 'facebook',
    label: 'Facebook',
    icon: 'Facebook'
  }
];

const characterRef = ref(null);
const circle = ref(null);
const transition = { opacity: [0, 1], scale: [0, 1] };

onMounted(() => {
  inView(
    characterRef.value,
    () => {
      animate(circle.value, transition, { duration: 0.5, delay: props.delay, easing: spring() });
      animate(`[data-character=${props.character}] [data-social] [data-social-link]`, transition, {
        duration: 0.2,
        delay: stagger(0.2, { start: props.delay + 0.2 }),
        easing: spring()
      });
    },
    { amount: 0.4 }
  );
});
</script>
