<template>
  <div>
    <Container v-if="props.title"
               class="md:flex md:justify-between pb-[10.25vw] md:pb-[3.4vw]"
    >
      <span class="text-[10vw] md:text-[3vw] leading-[12vw] md:leading-[3.5vw] uppercase font-oswald text-white">
        {{ props.title }}
      </span>
      <SliderNavigation v-if="!disabled"
                        @next="slider.moveToIdx(slider.track.details.abs + (size.isMobile ? perView : perViewMd), true, {duration:1000})"
                        @prev="slider.moveToIdx(slider.track.details.abs - (size.isMobile ? perView : perViewMd), true, {duration:1000})"
                        class="hidden md:block"
      />
    </Container>

    <div class="md:px-[5.62vw]">
      <div ref="container"
           class="keen-slider overflow-visible pl-[6vw] md:pl-0 max-w-full"
      >
        <slot />
      </div>
    </div>
    <div class="px-[6vw] md:hidden">
      <div class="w-full bg-black/20 h-[0.5vw] mt-[5vw] rounded-full">
        <div class="bg-turquoise h-[0.5vw] rounded-full transition-all duration-300"
             :style="{width: progress + '%'}"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  useKeenSlider,
  type KeenSliderInstance,
  type KeenSliderOptions
} from 'keen-slider/vue.es';
import { onMounted, ref, watch } from 'vue';
import { screenSize } from '~/composables/screen-size';

type SliderProps = {
  perView?: number;
  perViewMd?: number;
  title?: string;
}

const props = withDefaults(defineProps<SliderProps>(), {
  perView: 1.5,
  perViewMd: 3,
  title: ''
});

const progress = ref(0);
const disabled = ref(false);
const size = screenSize();

const update = (e: KeenSliderInstance) => {
  progress.value = ((e.track.details.rel + 1) / e.track.details.slides.length) * 100;
};

const options:KeenSliderOptions = {
  loop: true,
  mode: 'snap',
  slides: {
    perView: props.perView,
    spacing: () => 6 * size.value.width / 100
  },
  defaultAnimation: {
    duration: 6000
  },
  breakpoints: {
    '(min-width: 768px)': {
      slides: {
        perView: props.perViewMd,
        spacing: () => 2 * size.value.width / 100
      }
    }
  },
  slideChanged: e => update(e),
  created: (e: KeenSliderInstance) => {
    if (size.value.width > 768 && e.track.details.slides.length <= props.perViewMd) {
      disabled.value = true;
    }

    update(e);
  }
};

const [container, slider] = useKeenSlider(options);

onMounted(async () => {
  if(!size.value.isMobile) {
    options.rubberband = false;
    slider.value.update(options);
  }
});

watch(() => disabled.value, value => {
  if (slider.value) {

    options.loop = !value;
    options.drag = !value;

    slider.value.update(options);
  }
});
</script>

