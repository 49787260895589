<template>
  <div ref="shape">
    <SvgShapeGearSharpSvg class="w-full h-full" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import useParallax from '@/composables/use-parallax';

type SvgShapeProps = {
  animate?: boolean
}

const props = withDefaults(defineProps<SvgShapeProps>(), {
  animate: true
});

const shape = ref(null);
useParallax({ element: shape, run: props.animate });
</script>
