<template>
  <Container class="space-y-[6vw] md:space-y-0 md:grid grid-cols-3 md:gap-x-[2vw]">
    <div v-for="(card, key) in items"
         class="rounded-[5vw] md:rounded-[1.25vw] px-[6vw] md:px-[3.688vw] py-[12vw] md:py-0 md:h-[21.5vw] text-center flex items-center flex-col md:justify-center"
         :class="`bg-${card.backgroundColor}`"
         :key="key"
    >
      <div class="text-[10vw] md:text-[2.5vw] leading-[10vw] md:leading-[2.5vw] font-work font-extrabold text-white md:max-w-[18vw]">
        {{ card.title }}
      </div>
      <div class="w-fit pt-[8vw] md:pt-[1.5vw]"
           v-if="card.cta"
      >
        <ButtonRegular class=""
                       v-bind="card.cta"
                       @click="handleClick(card.title, card.cta)"
        >
          {{ card.cta.label }}
        </ButtonRegular>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { useNuxtApp }from '#imports';
import type { CtaType } from '~/types';

type CardFamilyGuidanceProps = {
  items: Array<{
    title: string;
    content?: string;
    backgroundColor: string,
    cta?: CtaType
  }>;
};

const { $event } = useNuxtApp();
defineProps<CardFamilyGuidanceProps>();

function handleClick(title: string, cta: CtaType) {
  $event('family-card', { props: { title, href: cta.href } });
}
</script>
