import { onMounted, onUnmounted, ref } from 'vue';
export type ScreenSize = {height:number, width: number, isMobile: boolean,}

export function screenSize() {
  const screenSize =  ref<ScreenSize>({ width: 0, height: 0, isMobile: true });

  const update = () => {
    if (!window) return;
    screenSize.value.width = window.innerWidth;
    screenSize.value.height = window.innerHeight;
    screenSize.value.isMobile = window.innerWidth < 768;
  };

  onMounted(() => {
    update();
    window.addEventListener('resize', update);
  });

  onUnmounted(() => window.removeEventListener('resize', update));

  return screenSize;
}

