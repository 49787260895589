<template>
  <div ref="shape">
    <svg viewBox="0 0 197 158"
         class="w-full h-full"
         :style="{ transform: `rotate(${rotate}deg)` }"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
    ><path d="M172.36 156.47a10.88 10.88 0 0 0 15.4-5.05 107.6 107.6 0 0 0 3.6-76.68A107.72 107.72 0 0 0 2.45 44.3a10.86 10.86 0 0 0 3.29 15.86l166.61 96.31Z"
           fill="currentColor"
    /></svg>
  </div>
</template>


<script setup lang="ts">
import { ref } from 'vue';
import useParallax from '@/composables/use-parallax';

type SvgShapeProps = {
  animate?: boolean,
  rotate?: number
}

const props = withDefaults(defineProps<SvgShapeProps>(), {
  animate: true,
  rotate: 0
});

const shape = ref(null);
useParallax({ element: shape, run: props.animate });
</script>