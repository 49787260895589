<template>
  <div class="w-full my-[6vw] md:my-[5vw] font-work text-indigo antialiased">
    <figure>
      <div ref="container"
           class="relative"
      >
        <div class="keen-slider rounded-[5vw] md:rounded-[1.25vw] overflow-hidden"
             :style="{ clipPath: 'content-box' }"
        >
          <div v-for="(item, index) in slides"
               :key="index"
          >
            <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
            <div class="aspect-w-16 aspect-h-9 keen-slider__slide">
              <NuxtImg format="auto"
                        :src="item.asset"
                        
                        class="object-cover"
                        sizes="xs:85vw sm:85vw md:73vw lg:73vw xl:73vw xxl:73vw"
              />
            </div>
          </div>
        </div>

        <button @click="slider.prev()"
                class="absolute left-0 p-[2vw] hover:text-red duration-100 md:p-[1.5vw] text-white -translate-y-1/2 select-none top-1/2"
        >
          <span class="sr-only">Previous</span>
          <IconArrowRightS class="w-[6vw] h-[6vw] md:w-[3vw] md:h-[3vw] rotate-180 drop-shadow-lg" />
        </button>

        <button @click="slider.next()"
                class="absolute hover:text-red duration-100 right-0 p-[2vw] md:p-[1.5vw] text-white -translate-y-1/2 select-none top-1/2"
        >
          <span class="sr-only">Next</span>
          <IconArrowRightS class="w-[6vw] h-[6vw] md:w-[3vw] md:h-[3vw] drop-shadow-lg" />
        </button>
      </div>
      <div class="flex items-start justify-between mt-[2vw] md:mt-[1vw] md:px-0 text-[4.25vw] leading-[6vw] md:text-[1.25vw] md:leading-[1.875vw]">
        <figcaption>
          {{ slides[current].caption }}
        </figcaption>

        <div class="relative flex items-center justify-center flex-shrink-0"
             v-if="slider && slider.track.details.slides.length > 1"
        >
          <div class="flex pl-[4vw] md:pl-[0.5vw]">
            <span class="font-bold">{{ current + 1 }}</span>&nbsp;- {{ slider.track.details.slides.length }}
          </div>
        </div>
      </div>
    </figure>
  </div>
</template>

<script setup lang="ts">
import { type KeenSliderInstance, useKeenSlider } from 'keen-slider/vue.es';
import { onMounted, ref } from 'vue';

type BlogSliderType = {
  slides: Array<{
    asset: string;
    caption: string;
  }>;
};

defineProps<BlogSliderType>();

const current = ref(0);

const update = (e: KeenSliderInstance) => {
  current.value = e.track.details.rel;
};

onMounted(async () => {
  // temporary fix for route change bug
  setTimeout(() => {
    if (slider.value) slider.value.update();
  }, 200);
});

const [container, slider] = useKeenSlider({
  slides: {
    perView: 1
  },
  loop: true,
  slideChanged: e => update(e)
});
</script>
