<template>
  <div class="relative">
    <Motion v-for="character in characters"
            :initial="character.animation.initial"
            :animate="{ x: [character.animation.initial.x, 0], y: [character.animation.initial.y, 0] }"
            :transition="{ ease: [0.22, 1, 0.36, 1], ...character.animation.transition }"
            :key="character.key"
            class="absolute w-[22.5vw] h-[37.5vw]"
            :class="character.class"
    >
      <NuxtImg format="auto"
               :src="character.src"
               :alt="character.label"
               class="w-full h-full"
               :class="character.imgClass"
               sizes="xs:50vw sm:50vw md:30vw lg:30vw xl:30vw xxl:30vw"
      />
    </Motion>
  </div>
</template>
<script setup lang="ts">
import { Motion } from '@motionone/vue/dist/motion-vue.ssr.js';

const characters = [
  {
    key: 'angela',
    label: 'Angela',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/angela.png',
    class: 'top-[-7vw] left-[-10vw]',
    imgClass: 'rotate-[105deg]',
    animation: {
      transition :{
        duration: 0.6,
        delay: 0.15
      },
      initial: {
        x: -100,
        y: -100
      }
    }
  },
  {
    key: 'ginger',
    label: 'Ginger',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/ginger.png',
    class: 'top-[-20vw] left-[26vw]',
    imgClass: 'rotate-[150deg]',
    animation: {
      transition :{
        duration: 0.6,
        delay: 0.45
      },
      initial: {
        x: -50,
        y: -100
      }
    }
  },
  {
    key: 'hank',
    label: 'Hank',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/hank.png',
    class: 'top-[-19.5vw] right-[10vw]',
    imgClass: 'rotate-[-150deg]',
    animation: {
      transition :{
        duration: 0.6,
        delay: 0.3
      },
      initial: {
        x:  100,
        y: -100
      }
    }
  },
  {
    key: 'ben',
    label: 'Ben',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/ben.png',
    class: 'right-[-10vw] bottom-[10vw]',
    imgClass: '-rotate-90',
    animation: {
      transition :{
        duration: 0.6,
        delay: 0.15
      },
      initial: {
        x: 100,
        y: 0
      }
    }
  },
  {
    key: 'becca',
    label: 'Becca',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/becca.png',
    class: 'right-[3vw] bottom-[-15vw]',
    imgClass: 'rotate-[-27deg]',
    animation: {
      transition :{
        duration: 0.6,
        delay: 0.45
      },
      initial: {
        x: 100,
        y: 100
      }
    }
  },
  {
    key: 'tom',
    label: 'Tom',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/tom.png',
    class: 'bottom-[-17vw] left-[0vw]',
    imgClass: 'rotate-[26deg]',
    animation: {
      transition :{
        duration: 0.6,
        delay: 0.3
      },
      initial: {
        x: -100,
        y: 100
      }
    }
  }
];

</script>
