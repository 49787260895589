export function getFullLanguage(locale: string): string {
  switch (locale) {
  case 'en':
    return 'English';
  case 'de':
    return 'Deutsch';
  case 'fr':
    return 'Français';
  case 'es':
    return 'Español';
  case 'pt':
    return 'Português';
  case 'it':
    return 'Italiano';
  case 'ru':
    return 'Pусский';
  case 'ar':
    return 'العربية';
  case 'tr':
    return 'Türkçe';
  case 'ja':
    return '日本語';
  case 'ko':
    return '한국어';
  case 'zh-trad':
    return '中文 (繁体)';
  case 'zh-simpl':
    return '中文（简体）';
  case 'zh-simp':
    return '中文（简体）';
  case 'hi':
    return 'हिन्दी';
  case 'zh':
    return '中文（简体）';
  case 'zh-hant':
    return '中文 (繁体)';
  case 'vi':
    return 'Tiếng Việt';
  case 'th':
    return 'ภาษาไทย';
  case 'id':
    return 'Bahasa Indonesia';
  default:
    return 'English';
  }
}

export function getLanguageOrder(locale: string): number {
  switch (locale) {
  case 'en':
    return 1;
  case 'de':
    return 2;
  case 'fr':
    return 3;
  case 'es':
    return 4;
  case 'pt':
    return 5;
  case 'it':
    return 6;
  case 'ru':
    return 7;
  case 'ar':
    return 8;
  case 'tr':
    return 9;
  case 'ko':
    return 10;
  case 'ja':
    return 11;
  case 'zh':
    return 12;
  case 'zh-simp':
    return 13;
  case 'zh-simpl':
    return 14;
  case 'zh-trad':
    return 15;
  case 'zh-hans':
    return 16;
  case 'zh-hant':
    return 17;
  case 'hi':
    return 18;
  case 'vi':
    return 19;
  case 'th':
    return 20;
  case 'id':
    return 21;
  default:
    return 999;
  }
}

export function languageSortFn(locale1: string, locale2: string): number {
  return getLanguageOrder(locale1) - getLanguageOrder(locale2);
}
