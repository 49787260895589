<template>
  <Container class="relative overflow-hidden py-[24vw] md:py-[8vw] font-work">
    <h1 class="text-[12vw] text-indigo text-center leading-none md:text-[5vw] md:leading-none font-extrabold tracking-[-0.01em]">
      My Talking Hank: Islands
      <br>
      Giveaway Winners
    </h1>
  </Container>
</template>


<script setup lang="ts">
import { useNuxtApp } from '#imports';
import { onMounted } from 'vue';

const { $lenisMount } = useNuxtApp();

onMounted(() => {
  $lenisMount();
});
</script>
