<template>
  <button @click="openModal"
          class="keen-slider__slide rounded-[2.5vw] md:rounded-[1.25vw] relative cursor-pointer group flex flex-col justify-between pt-[4vw] pb-[3vw] px-[4vw] md:p-[1.25vw] h-[42.5vw] md:h-[16vw]"
  >
    <NuxtImg format="auto"
              :src="image"
              sizes="xs:75vw sm:75vw md:29vw lg:29vw xl:29vw xxl:29vw"
              placeholder
              loading="lazy"
              :alt="title"
              class="absolute inset-0 w-full h-full object-cover rounded-[2.5vw] md:rounded-[1.25vw] transition duration-300 transform md:group-hover:scale-110 pointer-events-none"
    />

    <div class="absolute bottom-0 left-0 right-0 z-10 w-full pointer-events-none h-1/2"
         style="background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.0));"
    />

    <div class="self-start">
      <div v-if="badge"
           class="relative rounded-[1.25vw] md:rounded-[0.3vw] px-[2vw] md:px-[0.5vw] py-[1vw] md:py-[0.25vw] uppercase text-white font-oswald text-[3.25vw] md:text-[1vw] leading-[4vw] md:leading-[1.25vw] font-medium tracking-[-0.01em]"
           :class="[`bg-${badgeColor}`]"
      >
        {{ badge }}
      </div>
    </div>

    <div class="absolute inset-0 flex items-center justify-center duration-300 scale-75 md:scale-100 md:opacity-0 md:group-hover:opacity-100">
      <ButtonPlay />
    </div>

    <div v-if="showTitleOnVideoCard"
         class="relative uppercase text-white text-left text-[5vw] md:text-[1.5vw] leading-[6vw] md:leading-[1.75vw] font-oswald tracking-[-0.01em] font-medium"
    >
      {{ title }}
    </div>
  </button>
</template>

<script setup lang="ts">
import { useState, useNuxtApp } from '#imports';

type VideoCard = {
  id: string;
  videoPlaylistId: string;
  title: string,
  image: string,
  badge?: string,
  badgeColor?: string
  showTitleOnVideoCard?: boolean
}

const props = withDefaults(defineProps<VideoCard>(), {
  badge: '',
  badgeColor: '',
  id: '',
  default: true
});

const { $event } = useNuxtApp();
const isOpen = useState('video-popup', () => false);
const playlist = useState('video-playlist', () => '');

function openModal() {
  $event('card-video-click', { props: { id: props.id } });
  isOpen.value = true;
  playlist.value = props.videoPlaylistId;
}
</script>
