<template>
  <div ref="shape">
    <svg viewBox="0 0 72 155"
         class="w-full h-full"
         :style="{ transform: `rotate(${rotate}deg)` }"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M36.14 154.4C55.6136 154.4 71.4 119.881 71.4 77.3C71.4 34.7188 55.6136 0.200012 36.14 0.200012C16.6665 0.200012 0.880005 34.7188 0.880005 77.3C0.880005 119.881 16.6665 154.4 36.14 154.4Z"
            fill="currentColor"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import useParallax from '@/composables/use-parallax';

type SvgShapeProps = {
  animate?: boolean,
  modifier?: number,
  rotate?: number
}

const props = withDefaults(defineProps<SvgShapeProps>(), {
  animate: true,
  modifier: 1,
  rotate: 0
});

const shape = ref(null);
useParallax({ element: shape, run: props.animate, modifier: props.modifier });
</script>