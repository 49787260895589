<template>
  <div class="bg-indigo p-[5vw] md:p-[1.25vw] rounded-[5vw] md:rounded-[1.25vw] flex items-center flex-wrap">
    <div class="flex items-center flex-grow">
      <GameIcon :src="game.icon"
                :alt="game.title"
                class="w-[16vw] h-[16vw] md:w-[6vw] md:h-[6vw] md:mr-[1.5vw] mr-[6vw] flex-shrink-0"
      />
      <div class="md:flex-grow">
        <h4 class="text-white text-[5vw] leading-[7vw] md:text-[1.875vw] md:leading-[2.125vw] font-extrabold tracking-[-0.01em] md:max-w-[22.5vw]">
          {{ title }}
        </h4>
      </div>
    </div>

    <div class="gap-[2vw] md:gap-[1vw] grid-cols-1 md:grid-cols-2 flex-shrink-0 grid w-full md:w-auto mt-[4vw] md:mt-0">
      <NuxtLink :href="game.stores.arcade"
                class="bg-white rounded-[2.5vw] md:rounded-[0.625vw] flex items-center px-[0.5vw] h-[10vw] w-[32.5vw] md:h-[2.5vw] md:w-[8vw] place-self-center"
                :class="{ 'hidden ': deviceOs === 'iOS' ? link.type !== 'app-store' : false }"
      >
        <span class="sr-only">{{ getLabel(link.type) }}</span>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDeviceOs } from '../../composables/device-os';

type BlogCtaProps = {
  title: string;
  game: {
    icon: string;
    title: string;
    stores: {
      arcade: string;
      apple: string;
      google: string;
      huawei: string;
      amazon: string;
    };
  }[];
};

defineProps<BlogCtaProps>();
const deviceOs = useDeviceOs();
</script>
