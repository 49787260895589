<template>
  <div class="py-[4vw] md:py-[3vw] relative overflow-hidden">
    <BlogContainer>
      <div v-if="filteredLangs?.length > 1"
           class="md:col-span-8 md:col-start-3"
      >
        <PrivacyNavigation :language="language"
                           :slug="`/customs-privacy${content.slug}`"
                           :filtered-langs="filteredLangs"
        />
      </div>

      <div class="prose md:col-span-8 md:col-start-3"
           :style="{direction: ltr ? 'ltr' : 'rtl'}"
      >
        <ContentRenderer :value="content" />
      </div>
    </BlogContainer>
  </div>
</template>

<script setup lang="ts">
import { useHead } from '#imports';
import { computed }   from 'vue';
import usePrivacyNavigation from '~/composables/use-privacy-navigation';

type TemplatesPrivacySingleCustomProps = {
    content: any;
    language: string | undefined;
}

const props = defineProps<TemplatesPrivacySingleCustomProps>();

const filteredLangs = await usePrivacyNavigation(props.content._dir);

/**
 * The LTR property is defined the wrong way in the content
 *
 * LTR = true means the text is RTL
 * LTR = false means the text is LTR
 */
const ltr = computed(() => {
  return !props.content.ltr;
});

useHead({
  title: props.content.title,
  meta: [
    { hid: 'og:title', property: 'og:title', content: props.content.title },
    { hid: 'twitter:title', name: 'twitter:title', content: props.content.title }
  ]
});
</script>