<template>
  <div ref="shape">
    <svg fill="none"
         class="w-full h-full"
         :style="{ transform: `rotate(${rotate}deg)` }"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 154 137"
    ><path d="M106.8.03H46.92a16.47 16.47 0 0 0-14.26 8.23L2.72 60.13a16.46 16.46 0 0 0 0 16.46l29.94 51.86a16.476 16.476 0 0 0 14.26 8.23h59.88a16.456 16.456 0 0 0 14.26-8.23L151 76.59a16.456 16.456 0 0 0 0-16.46L121.06 8.26A16.46 16.46 0 0 0 106.8.03Z"
           fill="currentColor"
    /></svg>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import useParallax from '@/composables/use-parallax';

type SvgShapeProps = {
  animate?: boolean,
  rotate?: number
}

const props = withDefaults(defineProps<SvgShapeProps>(), {
  animate: true,
  rotate: 0
});

const shape = ref(null);
useParallax({ element: shape, run: props.animate });
</script>