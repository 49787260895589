<template>
  <div class="rounded-[5vw] md:rounded-[1.125vw] overflow-hidden relative"
       :class="`bg-${color}`"
       :style="{ clipPath: 'content-box' }"
  >
    <div class="h-[88vw] md:h-[28vw] w-ful">
      <Transition enter-active-class="duration-300 ease-in-out-cubic"
                  enter-from-class="-translate-y-full"
                  enter-to-class="translate-y-0"
                  leave-active-class="duration-500 ease-in-out-cubic"
                  leave-from-class="translate-y-0"
                  leave-to-class="-translate-y-full"
      >
        <div v-show="!open"
             class="relative"
        >
          <NuxtImg format="auto"
                    class="w-full h-[88vw] md:h-[28vw] object-cover"
                    :src="image"
                    :alt="title"
                    sizes="xs:88vw sm:88vw md:24.5vw lg:24.5vw xl:24.5vw xxl:24.5vw"
                    loading="lazy"
          />

          <GameIcon v-if="icon"
                    :src="icon"
                    class="md:w-[7.5vw] md:h-[7.5vw] absolute md:top-[1.5vw] md:left-[1.5vw] translate-y-"
          />

          <NuxtLink v-if="link"
                    class="absolute bottom-0 w-full text-white bg-indigo text-[4vw] leading-[5vw] md:text-[1.125vw] md:leading-[1.375vw] font-oswald font-medium uppercase tracking-[-0.01em] text-center py-[2vw] md:py-[0.5vw] flex justify-center border border-white/20 md:hover:bg-indigo-hover duration-200"
                    :href="link"
                    @click="handleVisitWebsite"
          >
            Visit website
            <IconArrowRight class="h-[5vw] w-[5vw] ml-[2vw] md:h-[1.25vw] md:w-[1.25vw] md:ml-[0.5vw]" />
          </NuxtLink>
        </div>
      </Transition>
    </div>

    <Transition enter-active-class="duration-500 ease-in-out-cubic"
                enter-from-class="translate-y-full"
                enter-to-class="translate-y-0"
                leave-active-class="duration-300 ease-in-out-cubic"
                leave-from-class="translate-y-0"
                leave-to-class="translate-y-full"
    >
      <div v-show="open"
           class="h-[88vw] md:h-[28vw] top-0 left-0 right-0 absolute z-20 flex flex-col justify-center items-center md:space-y-[0.5vw] space-y-[1vw]"
           :class="`bg-${color}`"
      >
        <ButtonStore v-if="stores.arcade"
                     :type="ButtonStoreType.ARCADE"
                     :href="stores.arcade"
                     class="w-[50vw] md:h-[4vw] md:w-[12.5vw]"
                     @click="handleInstall('arcade')"
        />

        <ButtonStore v-if="stores.apple"
                     :type="ButtonStoreType.APPLE"
                     :href="stores.apple"
                     class="w-[50vw] md:h-[4vw] md:w-[12.5vw]"
                     @click="handleInstall('apple')"
        />

        <ButtonStore v-if="stores.google"
                     :type="ButtonStoreType.GOOGLE"
                     :href="stores.google"
                     class="w-[50vw] md:h-[4vw] md:w-[12.5vw]"
                     @click="handleInstall('google')"
        />

        <ButtonStore v-if="stores.huawei"
                     :type="ButtonStoreType.HUAWEI"
                     :href="stores.huawei"
                     class="w-[50vw] md:h-[4vw] md:w-[12.5vw]"
                     @click="handleInstall('huawei')"
        />

        <ButtonStore v-if="stores.amazon"
                     :type="ButtonStoreType.AMAZON"
                     :href="stores.amazon"
                     class="w-[50vw] md:h-[4vw] md:w-[12.5vw]"
                     @click="handleInstall('amazon')"
        />
      </div>
    </Transition>

    
    <div class="relative h-[22vw] md:h-[6.5vw] flex md:px-[1.5vw] px-[5vw] z-30"
         :class="`bg-${color}`"
    >
      <h3 class="flex-grow my-auto font-oswald md:pr-[1.5vw] pr-[7vw] md:text-[1.875vw] tracking-[-0.01em] text-[5.75vw] text-white md:leading-[2.25vw] leading-[7vw] uppercase line-clamp-2">
        {{ title }}
      </h3>

      <div class="flex items-center justify-end md:w-[7.5vw] w-[27vw] flex-shrink-0">
        <Transition mode="out-in"
                    enter-active-class="duration-300 ease-out"
                    enter-from-class="-translate-y-4 opacity-0"
                    enter-to-class="translate-y-0 opacity-100"
                    leave-active-class="duration-300 ease-in"
                    leave-from-class="translate-y-0 opacity-100"
                    leave-to-class="translate-y-4 opacity-0"
        >
          <!-- If the App is on Arcade, we push this link-->
          <ButtonStore v-if="stores.arcade && deviceOs === 'iOS'"
                       :type="ButtonStoreType.ARCADE"
                       :href="stores.arcade"
                       class="w-[50vw] md:h-[4vw] md:w-[12.5vw]"
                       @click="handleInstall('arcade')"
          />

          <!-- If the App is on Apple App Store, but not on Arcade, we push this link-->
          <ButtonStore v-else-if="stores.apple && deviceOs === 'iOS'"
                       :type="ButtonStoreType.APPLE"
                       :href="stores.apple"
                       class="w-[50vw] md:h-[4vw] md:w-[12.5vw]"
                       @click="handleInstall('iOS')"
          />
          <ButtonTiny v-else-if="!open"
                      @click="downloadToggle"
          >
            Download
            <IconArrowDownSFill class="h-[5vw] w-[5vw] md:h-[1.5vw] md:w-[1.5vw] fill-white" />
          </ButtonTiny>

          <ButtonTiny v-else
                      @click="downloadToggle"
          >
            Close
            <IconClose class="h-[5vw] w-[5vw] md:h-[1.5vw] md:w-[1.5vw] fill-white" />
          </ButtonTiny>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import{ ref } from'vue';
import { useNuxtApp } from '#imports';
import { useDeviceOs } from '../../composables/device-os';
import { ButtonStoreType } from '~/enums';

const { $event } = useNuxtApp();
const deviceOs = useDeviceOs();
const open = ref(false);

type CardGameProps = {
  id: string;
  title: string;
  image?: string;
  icon?: string;
  color?: string;
  link?: string;
  stores: {
    arcade: string;
    apple: string;
    google: string;
    amazon: string;
    huawei: string;
  }
}

const props = withDefaults(defineProps<CardGameProps>(), {
  image: '',
  icon: '',
  color: 'green',
  link: ''
});

function downloadToggle() {
  $event('card-game-toggle-download', { props : { title: props.title } });
  open.value = !open.value;
}

function handleVisitWebsite() {
  $event('card-game-visit', { props : { title: props.title } });
}

function handleInstall(store: string) {
  $event('install', { props : { title: props.title, store } });
}
</script>

