<template>
  <component :is="componentToShow"
             :href="href"
             :target="href && href.toLowerCase().startsWith('http') ? '_blank' : ''"
             :download="href ? '' : null"
             class="flex items-center focus:outline outline-[2px] focus:outline-blue justify-between rounded-full uppercase font-medium md:hover:bg-opacity-[0.85] duration-300 font-oswald group tracking-[-0.01em] pr-[3vw] md:pr-[0.625vw]"
             :class="[
               `bg-${backgroundColor}`,
               { 'text-[6vw] md:text-[1.5vw] py-[3vw] md:py-[0.75vw] pl-[6vw] md:pl-[1.5vw] leading-[7vw] md:leading-[1.75vw] ': size === 'large' },
               { 'text-[7.5vw] md:text-[1.875vw] py-[3vw] md:py-[0.75vw] pl-[6vw] md:pl-[1.5vw] leading-[9vw] md:leading-[2.25vw]': size === 'xl' },
               {
                 'text-white': color === 'white',
               },
               {
                 'text-indigo': color === 'indigo',
               }
             ]"
  >
    <span><slot /></span>

    <span class="rounded-full flex items-center justify-center h-[8vw] md:h-[2vw] w-[8vw] md:w-[2vw] ml-[4vw] md:ml-[1vw] relative overflow-hidden"
          :class="{
            'bg-white text-indigo': color === 'white',
            'bg-indigo text-white': color === 'indigo',
          }"
    >
      <IconArrowDownload class="h-[4vw] w-[4vw] md:h-[1.25vw] md:w-[1.25vw] absolute md:top-[0.375vw] md:left-[0.375vw] duration-200 md:group-hover:translate-y-[2vw]" />
      <IconArrowDownload class="hidden md:block h-[4vw] w-[4vw] md:h-[1.25vw] md:w-[1.25vw] absolute md:top-[0.375vw] md:left-[0.375vw] duration-200 -translate-y-[2vw] md:group-hover:translate-y-0" />
    </span>
  </component>
</template>

<script setup lang="ts">
import { resolveComponent } from '#imports';
import { computed } from 'vue';

type ButtonRegularProps = {
  href?: string,
  size?: 'large' | 'xl',
  backgroundColor?: 'indigo' | 'red' | 'white';
  color?: 'white' | 'indigo',
  gateLink?: boolean,
}

const props = withDefaults(defineProps<ButtonRegularProps>(), {
  href: '',
  size: 'large',
  arrowDirection: 'down',
  backgroundColor: 'indigo',
  color: 'white',
  gateLink: false
});

/**
 * Component to render
 *
 * In order to render NuxtLink as a dynamic component, we need to use
 * the resolveComponent function.
 */
const componentToShow = computed(() => {
  if (props.href && props.href.length > 0 && props.gateLink) {
    return resolveComponent('GatedLink');
  }

  if (props.href && props.href.length > 0) {
    return resolveComponent('NuxtLink');
  }
  return 'button';
});
</script>
