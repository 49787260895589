enum DeviceOs {
  android = 'Android',
  ios = 'iOS',
  unknown = 'unknown'
}

export function useDeviceOs(): DeviceOs {
  if (typeof window === 'undefined') return;

  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) return DeviceOs.android;
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) return DeviceOs.ios;
  return DeviceOs.unknown;
}
