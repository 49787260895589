<template>
  <NuxtLink :href="href"
            target="_blank"
            class="relative"
  >
    <NuxtImg format="auto"
             :src="`https://cdn-ttf.o7web.com/assets/public/img/stores/${background}/${image}`"
             :alt="label[type]"
             class="w-full h-full"
             loading="lazy"
    />
    <span class="sr-only">{{ label[type] }}</span>
  </NuxtLink>
</template>

<script setup lang="ts">
import { ButtonStoreType } from '~/enums';

type ButtonStoreProps = {
    type: ButtonStoreType;
    href: string;
    background?: 'dark' | 'light';
}

const props = withDefaults(defineProps<ButtonStoreProps>(), {
  background: 'dark'
});

const image = props.type === ButtonStoreType.AMAZON ? 'amazon.png':
  props.type === ButtonStoreType.APPLE ? 'apple.png':
    props.type === ButtonStoreType.ARCADE ? 'arcade.png':
      props.type === ButtonStoreType.GOOGLE ? 'google-2.png':
        props.type === ButtonStoreType.HUAWEI ? 'huawei.png': '';

const label = {
  apple: 'Download on the App Store',
  google: 'Download on Google Play',
  huawei: 'Explore it on AppGallery',
  amazon: 'Available at Amazon appstore',
  arcade: 'Find it on Apple Arcade'
};
</script>
