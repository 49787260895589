<template>
  <div class="overflow-hidden">
    <div class="hidden md:flex justify-center md:space-x-[1vw]">
      <SectionFilterCard v-for="item in characters"
                         :key="item.value"
                         @click="handleClick(item.value)"
                         v-bind="item"
                         :selected="item.value === selected"
      />
    </div>

    <div class="md:hidden px-[6vw]">
      <div ref="container"
           class="max-w-full !overflow-visible keen-slider"
      >
        <SectionFilterCard v-for="item in characters"
                           :key="item.value"
                           class="keen-slider__slide"
                           @click="handleClick(item.value)"
                           v-bind="item"
                           :selected="item.value === selected"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNuxtApp } from '#imports';
import {
  useKeenSlider,
  type KeenSliderOptions
} from 'keen-slider/vue.es';
import { useRouter, useRoute } from 'vue-router';
import { screenSize } from '~/composables/screen-size';

type SectionFilterCharacterProps = {
    selected?: string
}

defineProps<SectionFilterCharacterProps>();

const { $event } = useNuxtApp();
const size = screenSize();
const route = useRoute();
const router = useRouter();

const options:KeenSliderOptions = {
  loop: false,
  mode: 'free-snap',
  slides: {
    perView: 3.7,
    spacing: () => 3 * size.value.width / 100
  }
};

const [container] = useKeenSlider(options);

const characters = [
  { image: '', value: 'all', backgroundColor: 'red', label: 'All' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/tom.png', value: 'tom', backgroundColor: 'blue', label: 'Tom' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/angela.png', value: 'angela', backgroundColor: 'pink', label: 'Angela' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/hank.png', value: 'hank', backgroundColor: 'yellow', label: 'Hank' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/ginger.png', value: 'ginger', backgroundColor: 'orange', label: 'Ginger' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/ben.png', value: 'ben', backgroundColor: 'green', label: 'Ben' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/becca.png', value: 'becca', backgroundColor: 'purple', label: 'Becca' }
];

function handleClick(item: string) {
  $event('filter-set-character', { props : { value: item } });

  router.push({
    query: {
      ...route.query,
      character: item === 'all' ? undefined : item
    }
  });
}

</script>