<template>
  <div ref="shape">
    <svg viewBox="0 0 175 175"
         fill="none"
         class="w-full h-full"
         xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M81.83 6.29c2.93-7.72 7.73-7.72 10.66 0 2.93 7.72 9.56 8.78 14.74 2.34 5.18-6.44 9.74-5 10.14 3.29.4 8.29 6.38 11.31 13.3 6.78 6.92-4.53 10.79-1.71 8.62 6.27-2.17 7.98 2.58 12.72 10.55 10.55 7.97-2.17 10.8 1.71 6.27 8.62-4.53 6.91-1.48 12.9 6.78 13.3 8.26.4 9.73 5 3.29 10.14-6.44 5.14-5.39 11.81 2.33 14.74 7.72 2.93 7.73 7.73 0 10.66-7.73 2.93-8.77 9.57-2.33 14.74 6.44 5.17 5 9.74-3.29 10.14-8.29.4-11.31 6.39-6.78 13.3 4.53 6.91 1.71 10.8-6.27 8.63-7.98-2.17-12.72 2.58-10.55 10.55 2.17 7.97-1.71 10.79-8.62 6.27-6.91-4.52-12.9-1.48-13.3 6.77-.4 8.25-5 9.74-10.14 3.3-5.14-6.44-11.81-5.4-14.74 2.33-2.93 7.73-7.73 7.73-10.66 0-2.93-7.73-9.57-8.78-14.75-2.33-5.18 6.45-9.73 5-10.13-3.3-.4-8.3-6.39-11.3-13.3-6.77-6.91 4.53-10.8 1.7-8.63-6.27s-2.58-12.72-10.55-10.55c-7.97 2.17-10.8-1.71-6.27-8.63 4.53-6.92 1.48-12.9-6.77-13.3-8.25-.4-9.74-5-3.3-10.14 6.44-5.14 5.4-11.81-2.33-14.74-7.73-2.93-7.73-7.73 0-10.66 7.73-2.93 8.78-9.56 2.33-14.74-6.45-5.18-5-9.74 3.3-10.14 8.3-.4 11.3-6.38 6.77-13.3-4.53-6.92-1.71-10.79 6.27-8.62 7.98 2.17 12.72-2.58 10.55-10.55-2.17-7.97 1.71-10.8 8.63-6.27 6.92 4.53 12.9 1.48 13.3-6.78.4-8.26 5-9.73 10.13-3.29 5.13 6.44 11.81 5.39 14.75-2.34Z"
            fill="currentColor"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import useParallax from '@/composables/use-parallax';

type SvgShapeProps = {
  animate?: boolean
}

const props = withDefaults(defineProps<SvgShapeProps>(), {
  animate: true
});

const shape = ref(null);
useParallax({ element: shape, run: props.animate });
</script>