<template>
  <Transition enter-active-class="duration-300"
              enter-from-class="-translate-y-full"
              enter-to-class="translate-y-0"
              leave-active-class="duration-300"
              leave-from-class="translate-y-0"
              leave-to-class="-translate-y-full"
  >
    <div v-if="showAgeGate"
         data-lenis-prevent
         class="fixed inset-0 z-50 flex items-center justify-center bg-white"
    >
      <Transition mode="out-in"
                  enter-active-class="duration-300"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="duration-300"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
      >
        <div v-if="showFailedState"
             class="w-[100vh] h-screen flex items-center justify-center font-work text-indigo"
        >
          <div class="text-center md:px-[1.5vw] px-[7vw] text-[6vw] md:text-[1.75vw] md:leading-[2.25vw] tracking-[-0.01em] font-semibold md:space-y-[1vw] space-y-[4vw]">
            <p>Sorry, this feature is currently not available.</p>
            <p>However, there is plenty of fun waiting to be discovered on our website.</p>
          </div>
        </div>
        <div v-else
             class="w-[100vh] h-screen mt-[24vw] md:mt-0"
        >
          <ComplianceCollector :collector="Collector.AgeGate"
                               @collector-status="handle($event)"
          />
        </div>
      </Transition>
      <button @click="showAgeGate = false"
              class="z-50 absolute top-[2vw] right-[2vw] md:top-[1vw] md:right-1/4 md:hover:opacity-80 duration-150"
      >
        <span class="sr-only">Close age gate</span>
        <IconClose class="w-[10vw] h-[10vw] md:w-[2.5vw] md:h-[2.5vw] text-indigo" />
      </button>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useState, useNuxtApp } from '#imports';
import { Collector, CollectorStatus, complianceResultState, ComplianceCollector } from '@outfit7/o7compliance-client';

const { $event } = useNuxtApp();
const showAgeGate = useState('age-gate-show', () => false);
const showFailedState = ref(false);
const link = useState('age-gate-link', () => undefined);

watch(showAgeGate, value => {
  if (value) {
    $event('age-gate-open');
    document.body.style.overflow = 'hidden';
  } else {
    $event('age-gate-close');
    document.body.style.overflow = 'auto';
  }
});

function handle(e: any) {
  if (e.value === CollectorStatus.Completed) {
    if (complianceResultState.O7Compliance_AgeLimitPassed === true) {
      window.open(link.value, '_blank');

      showAgeGate.value = false;
      link.value = undefined;
    } else {
      showFailedState.value = true;

      setTimeout(() => {
        showAgeGate.value = false;
        showFailedState.value = false;
        link.value = undefined;
      }, 8000);
    }
  }
}
</script>