import { computed, useAsyncData, queryContent } from '#imports';
import { languageSortFn } from '@/utils/lang';

export default async function usePrivacyNavigation(language: string){

  const { data } = await useAsyncData(
    `${language}-privacy-navigation`,
    () => queryContent(`/privacy/${language}/`)
      .only(['lang'])
      .find()
  );

  const filteredLangs = computed(() => {
    if (!data || !data.value) {
      return [];
    }
  
    let hasChineseLang = false;
    let hasTradChineseLang = false;
  
    const fLangs: string[] = [];
  
    data.value.forEach(({ lang }) => {
      if (fLangs.includes(lang)) {
        return;
      }
  
      if (lang === 'zh' || lang === 'zh-simp' || lang === 'zh-simpl') {
        if (hasChineseLang) {
          return;
        }
  
        hasChineseLang = true;
      }
  
      if (lang === 'zh-trad' || lang === 'zh-hans' || lang === 'zh-hant') {
        if (hasTradChineseLang) {
          return;
        }
  
        hasTradChineseLang = true;
      }
  
      fLangs.push(lang);
    });
  
    return fLangs.sort(languageSortFn);
  });

  return filteredLangs;
}