<template>
  <svg width="160"
       height="159"
       viewBox="0 0 160 159"
       fill="currentColor"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M79.8996 0.149903C64.2008 0.147925 48.8541 4.80145 35.8002 13.522C22.7463 22.2425 12.5717 34.6382 6.56316 49.1416C0.554596 63.645 -1.01804 79.6045 2.04415 95.0017C5.10634 110.399 12.6658 124.542 23.7665 135.643C34.8672 146.744 49.0105 154.303 64.4077 157.365C79.8049 160.427 95.7644 158.855 110.268 152.846C124.771 146.838 137.167 136.663 145.887 123.609C154.608 110.556 159.262 95.2085 159.26 79.5098C159.26 58.4622 150.898 38.2767 136.016 23.3938C121.133 8.51091 100.947 0.149903 79.8996 0.149903ZM79.8996 120.39C71.8143 120.39 63.9105 117.992 57.1879 113.5C50.4652 109.008 45.2255 102.624 42.1314 95.1541C39.0373 87.6842 38.2277 79.4646 39.8051 71.5347C41.3824 63.6047 45.2759 56.3204 50.993 50.6033C56.7102 44.8861 63.9943 40.9926 71.9243 39.4153C79.8542 37.8379 88.0738 38.6476 95.5437 41.7417C103.014 44.8358 109.398 50.0754 113.89 56.7981C118.382 63.5208 120.78 71.4245 120.78 79.5098C120.78 90.3518 116.473 100.75 108.806 108.417C101.14 116.083 90.7416 120.39 79.8996 120.39Z"
          fill="currentColor"
    />
  </svg>
</template>
