<template>
  <div class="md:px-[23.5vw]">
    <div ref="slider"
         class="keen-slider"
    >
      <button @click="selectCharacter('all')"
              class="keen-slider__slide rounded-[2.5vw] md:rounded-[0.625vw] cursor-pointer md:w-[7.5vw] md:h-[7.5vw] w-[24vw] justify-center
            flex uppercase text-white text-[5vw] font-oswald md:text-[1.5vw] md:leading-[1.75vw] tracking-[-0.01em] border-[0.25vw] bg-red items-center"
              :class="route.query.character ? 'border-red' : 'border-white'"
      >
        All
      </button>
      <div v-for="character in options"
           @click="selectCharacter(character.value)"
           :key="character.value"
           :class="route.query.character === character.value ? 'border-white' : `border-transparent`"
           class="keen-slider__slide rounded-[2.5vw] md:rounded-[1vw] relative cursor-pointer md:w-[7.5vw] w-[24vw] border-4"
      >
        <NuxtImg format="auto"
                 :src="character.image"
                 :alt="character.value"
                 class="min-w-full -p-4"
                 loading="lazy"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  useKeenSlider,
  type KeenSliderOptions
} from 'keen-slider/vue.es';
import { screenSize } from '~/composables/screen-size';
import { useRouter, useRoute } from 'vue-router';

const size = screenSize();
const route = useRoute();
const router = useRouter();

const sliderOptions:KeenSliderOptions = {
  mode: 'free-snap',
  slides: {
    perView: 4,
    spacing: () => 3 * size.value.width / 100
  },
  breakpoints: {
    '(min-width: 768px)': {
      slides: {
        perView: 7,
        spacing: () => 1 * size.value.width / 100
      }
    }
  }
};

const [slider] = useKeenSlider(sliderOptions);

const options = [
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/tom.png', value: 'tom', color: 'blue'  },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/angela.png', value: 'angela', color: 'pink' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/ginger.png', value: 'ginger', color: 'orange' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/ben.png', value: 'ben', color: 'yellow' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/hank.png', value: 'hank', color: 'green' },
  { image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/icons/becca.png', value: 'becca', color: 'purple' }
];

const selectCharacter = (character: string) => {
  router.push({
    query: {
      character: character === 'all' ? undefined : character
    }
  });
};

</script>
