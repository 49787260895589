<template>
  <div ref="shape">
    <svg fill="none"
         class="w-full h-full"
         :style="{ transform: `rotate(${rotate}deg)` }"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 106 155"
    >
      <path d="M44.19 149.74 1.91 84.15a10.19 10.19 0 0 1 0-11l45-69.77a6.94 6.94 0 0 1 11.67 0l45 69.77a10.151 10.151 0 0 1 0 11l-42.27 65.59a10.154 10.154 0 0 1-8.56 4.696 10.147 10.147 0 0 1-8.56-4.696Z"
            fill="currentColor"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import useParallax from '@/composables/use-parallax';

type SvgShapeProps = {
  animate?: boolean,
  rotate?: number
}

const props = withDefaults(defineProps<SvgShapeProps>(), {
  animate: true,
  rotate: 0
});

const shape = ref(null);
useParallax({ element: shape, run: props.animate });
</script>