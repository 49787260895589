<template>
  <div class="bg-indigo md:pb-[10vw] pb-[20vw]"
       id="all"
  >
    <h2 class="font-work text-white font-extrabold text-[7.5vw] md:text-[5vw] leading-none tracking-[-0.01em] text-center md:pt-[8vw] pt-[16vw] md:mb-[5vw] mb-[10vw] md:max-w-[64vw] mx-auto">
      {{ title }}
    </h2>

    <SectionFilterCharacter v-if="menu"
                            :selected="character"
    />

    <div v-if="!hide"
         class="bg-[#49415a] md:mt-[4vw] mt-[7.5vw] relative overflow-hidden"
    >
      <Container class="w-full overflow-x-auto flex justify-center items-center py-[2.75vw] md:py-[1vw] md:space-x-[1vw] space-x-[4vw]"
                 ref="container"
      >
        <SectionFilterTab v-for="item in categories"
                          :key="item.value"
                          @click="setType(item.value)"
                          :selected="type === item.value"
        >
          {{ item.label }}
        </SectionFilterTab>
      </Container>

      <Transition enter-active-class="duration-500"
                  enter-from-class="translate-x-full"
                  enter-to-class="translate-x-0"
                  leave-active-class="duration-500"
                  leave-from-class="translate-x-0"
                  leave-to-class="translate-x-full"
      >
        <div v-show="gradient"
             class="absolute top-0 bottom-0 right-0 from-transparent bg-gradient-to-r to-indigo w-[11vw] pointer-events-none"
        />
      </Transition>
    </div>

    <Container>
      <slot />
    </Container>
  </div>
</template>
<script lang="ts" setup>
import { useNuxtApp } from '#imports';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { screenSize } from '../../composables/screen-size';

type SectionFilterProps = {
    type: string;
    character: string
    title: string
    categories: {
        value: string;
        label: string
    }[],
    hide?: boolean
    menu?: boolean
}


withDefaults(defineProps<SectionFilterProps>(), {
  menu: true
});

const { $event } = useNuxtApp();
const route = useRoute();
const router = useRouter();
const container = ref(null);
const gradient = ref(false);
const size = screenSize();

onMounted(() => {
  nextTick(() => {
    if (container.value) {
      if (container.value.$el.scrollWidth > container.value.$el.clientWidth) {
        container.value.$el.classList.remove('justify-center');
        gradient.value = true;
        container.value.$el.addEventListener('scroll', scrollHandler);
      }
    }
  });
});

onBeforeUnmount(() => {
  if (container.value) {
    container.value.$el.removeEventListener('scroll', scrollHandler);
  }
});

function scrollHandler() {
  const offset = 4 * (size.value.width / 100);
  const scrollToLeft = container.value.$el.scrollWidth - container.value.$el.clientWidth - container.value.$el.scrollLeft;

  if (scrollToLeft < offset ) {
    gradient.value = false;
  } else {
    gradient.value = true;
  }
}

const setType = (type: string) => {
  $event('filter-set-type', { props : { value: type } });

  router.push({
    query: {
      ...route.query,
      type: type === 'all' ? undefined : type
    }
  });
};
</script>
