<template>
  <Teleport to="body">
    <TransitionRoot :show="isOpen"
                    as="template"
                    leave="transition duration-200 ease-in"
                    leave-to="translate-y-full"
    >
      <div class="cookie-notice fixed bottom-0 left-1/2 -translate-x-1/2 z-50 w-full max-w-[945px] px-7 pt-8 pb-5 md:p-10 bg-white rounded-t-3xl overflow-auto min-h-[192px] max-h-[60%] flex flex-wrap gap-4 shadow-popup font-work"
           data-lenis-prevent
      >
        <h2 class="font-extrabold text-[2.5rem] text-indigo leading-[2.5rem] text-center md:text-left w-full">
          About cookies
        </h2>

        <template v-if="isGdprCountry">
          <div class="flex flex-col md:flex-row items-center md:items-start gap-4 md:gap-6">
            <p class="w-auto font-normal text-[1.0625rem] md:text-xl text-center md:text-left">
              We (Outfit7 and our partners) store and/or access certain personal data about your device using
              technologies, such as cookies, to improve your experience and to deliver web push notifications to you, if
              you choose to subscribe. For more information, read our
              <NuxtLink href="/cookies"
                        class="underline font-medium"
              >
                Cookie Policy
              </NuxtLink>.
              You or a grown up, if you are below 16 years old, can click on the accept button to agree to this, or
              reject button, if you do not agree to such use.
            </p>
            <div class="flex md:flex-col gap-4">
              <CookieButton label="ACCEPT"
                            type="confirm"
                            @click="handleConfirmButtonClick()"
              />
              <CookieButton label="REJECT"
                            type="deny"
                            @click="handleDenyButtonClick()"
              />
            </div>
          </div>
        </template>

        <template v-else>
          <div class="flex flex-col md:flex-row items-center md:items-start gap-4 md:gap-6">
            <p class="w-auto font-normal text-[1.0625rem] md:text-xl text-center md:text-left">
              This website uses cookies for the display of the website and its features. Please see more information
              about cookies used in our
              <NuxtLink href="/cookies"
                        class="underline font-medium"
              >
                Cookie Policy
              </NuxtLink>.
            </p>
            <CookieButton label="OK"
                          type="confirm"
                          @click="handleConfirmButtonClick()"
            />
          </div>
        </template>
      </div>
    </TransitionRoot>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { TransitionRoot } from '@headlessui/vue';
import useGeoIp from '~/composables/useGeoIp';
import { useWonderpush } from '~/composables/use-wonderpush';
import { RegulatoryRegion } from '~/enums';

const emit = defineEmits<{
  (e: 'set-consent', value: boolean): void
}>();

const { checkCountryEligibility } = useGeoIp();
const { setUserConsent, subscribeToNotifications } = useWonderpush();

const isOpen = ref(false);
const isGdprCountry = ref<boolean>();

async function open() {
  isGdprCountry.value = await checkCountryEligibility(RegulatoryRegion.GDPR);
  isOpen.value = true;
}

function close() {
  isOpen.value = false;
}

function handleConfirmButtonClick() {
  if (isGdprCountry.value && setUserConsent) setUserConsent(true);
  subscribeToNotifications();
  emit('set-consent', true);
  close();
}

function handleDenyButtonClick() {
  if (isGdprCountry.value && setUserConsent) setUserConsent(false);
  emit('set-consent', false);
  close();
}

onMounted(() => {
  open();
});
</script>
