<template>
  <div ref="shape">
    <svg fill="none"
         class="w-full h-full"
         :style="{ transform: `rotate(${rotate}deg)` }"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 156 156"
    ><path d="M74.96 3.29c1.54-4.33 4.06-4.33 5.6 0l10.14 28.5a7.4 7.4 0 0 0 10.35 4.28l27.32-13c4.15-2 5.93-.19 4 4l-13 27.32a7.41 7.41 0 0 0 4.29 10.35l28.47 10.14c4.33 1.54 4.33 4.06 0 5.6l-28.5 10.14a7.405 7.405 0 0 0-4.51 4.195 7.409 7.409 0 0 0 .22 6.155l13 27.32c2 4.15.19 5.93-4 4l-27.32-13a7.408 7.408 0 0 0-10.35 4.29l-10.14 28.5c-1.54 4.33-4.06 4.33-5.6 0l-10.13-28.5a7.405 7.405 0 0 0-4.195-4.51 7.406 7.406 0 0 0-6.155.22l-27.32 13c-4.15 2-5.94.19-4-4l13-27.32a7.399 7.399 0 0 0-4.28-10.35L3.35 80.48c-4.33-1.54-4.33-4.06 0-5.6l28.5-10.13a7.401 7.401 0 0 0 4.28-10.35l-13-27.32c-2-4.15-.19-5.94 4-4l27.32 13A7.4 7.4 0 0 0 64.8 31.8L74.96 3.29Z"
           fill="currentColor"
    /></svg>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import useParallax from '@/composables/use-parallax';

type SvgShapeProps = {
  animate?: boolean,
  rotate?: number
}

const props = withDefaults(defineProps<SvgShapeProps>(), {
  animate: true,
  rotate: 0
});

const shape = ref(null);
useParallax({ element: shape, run: props.animate });
</script>