<template>
  <component :is="componentToShow"
             :href="href"
             class="flex items-center focus:outline outline-[2px] focus:outline-blue rounded-full uppercase font-medium md:hover:bg-opacity-[0.85] duration-300 font-oswald group tracking-[-0.01em] space-x-[2vw] md:space-x-[0.5vw] py-[2vw] md:py-[0.5vw] pl-[2vw] md:pl-[0.5vw]"
             :class="[
               `bg-${backgroundColor}`,
               { 'text-[4vw] md:text-[1.125vw] leading-none pr-[4vw] md:pr-[1vw]': size === 'default' },
               { 'text-[6vw] md:text-[1.5vw] leading-[7vw] md:leading-[1.75vw] pr-[6vw] md:pr-[1.5vw]': size === 'large' },
               {
                 'text-white': color === 'white',
               },
               {
                 'text-indigo': color === 'indigo',
               }
             ]"
  >
    <span class="relative flex items-center justify-center flex-shrink-0 overflow-hidden rounded-full"
          :class="[`bg-${computedIconBackground} text-${backgroundColor}`,
                   { 'h-[8vw] md:h-[2vw] w-[8vw] md:w-[2vw]': size === 'default' },
                   { 'h-[10vw] md:h-[2.5vw] w-[10vw] md:w-[2.5vw]': size === 'large' },
          ]"
    >
      <component :is="`Icon${icon}`"
                 class="absolute duration-200 md:group-hover:translate-y-[2vw]"
                 :class="[
                   { 'h-[4vw] w-[4vw] md:h-[1.25vw] md:w-[1.25vw] md:top-[0.375vw] md:right-[0.375vw]': size === 'default' },
                   { 'h-[6vw] w-[6vw] md:h-[1.5vw] md:w-[1.5vw] md:top-[0.5vw] md:right-[0.5vw]': size === 'large' },
                 ]"
      />
      <component :is="`Icon${icon}`"
                 class="hidden md:block absolute duration-200 -translate-y-[2vw] md:group-hover:translate-y-0"
                 :class="[
                   { 'h-[4vw] w-[4vw] md:h-[1.25vw] md:w-[1.25vw] md:top-[0.375vw] md:right-[0.375vw]': size === 'default' },
                   { 'h-[6vw] w-[6vw] md:h-[1.5vw] md:w-[1.5vw] md:top-[0.5vw] md:right-[0.5vw]': size === 'large' },
                 ]"
      />
    </span>
    <span><slot /></span>
  </component>
</template>

<script setup lang="ts">
import { resolveComponent } from '#imports';
import { computed } from 'vue';

type ButtonLeftyProps = {
  href?: string,
  size?: 'default' | 'large',
  backgroundColor?: 'indigo' | 'red' | 'white';
  color?: 'white' | 'indigo',
  iconBackgroundColor?: false | 'white' | 'indigo' | 'red',
  icon: string;
}

const props = withDefaults(defineProps<ButtonLeftyProps>(), {
  href: '',
  size: 'large',
  arrowDirection: 'right',
  backgroundColor: 'indigo',
  iconBackgroundColor: false,
  color: 'white',
  icon: 'PlayFill'
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NuxtLink = resolveComponent('NuxtLink');

/**
 * Component to render
 *
 * In order to render NuxtLink as a dynamic component, we need to use
 * the resolveComponent function.
 */
const componentToShow = computed(() => {
  if (props.href && props.href.length > 0) {
    return resolveComponent('NuxtLink');
  }
  return 'button';
});

const computedIconBackground = computed(() => {
  if (props.iconBackgroundColor) {
    return props.iconBackgroundColor;
  }

  return props.color;
});
</script>
