<template>
  <div class="opacity-0 translate-y-[8vw] md:translate-y-[3vw]"
       ref="content"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { animate, inView } from 'motion';
import { ref, onMounted } from 'vue';

defineProps({
  px: {
    type: Boolean,
    default: true
  }
});

const content = ref(null);
onMounted(() => {
  inView(content.value, () => {
    animate(content.value, { opacity: 1, transform: 'translateY(0)' }, { duration: 1.1 });
  }, { amount: 0.25 });
});
</script>
