import { onMounted, type Ref } from 'vue';
import { scroll, animate } from 'motion';
import { screenSize } from '~/composables/screen-size';

/**
 * Used on shapes to give them a parallax effect
 *
 * Cannot be used on SVG elements, the element must be a div/span
 * @param element
 * @param amount
 * @param run
 */
export default function useParallax({ element, run = true, modifier = 1 }: { element: Ref, run?: boolean, modifier?: number }) {
  const size = screenSize();

  onMounted(() => {
    if (!run) return;

    if (!element.value) {
      return;
    }

    // get element square area in px
    const rect = element.value.getBoundingClientRect();
    const area = rect.width * rect.height;

    // Base values are set by feeling, they are not based on any calculations
    const baseValue = size.value.isMobile ? 10000 : 20000;

    scroll(animate(element.value, { rotate: ((baseValue * modifier) / Math.sqrt(area)) * 1 }), {
      target: element.value,
      offset: ['start end', 'end start']
    });
  });
}
