<template>
  <div>
    <button type="button"
            class="rounded-[2.5vw] text-[4.25vw] leading-[6vw] md:rounded-[0.625vw] text-indigo antialiased md:text-[1.125vw] md:leading-[1.5vw] text-opacity-50 md:p-[1vw] p-[4vw] bg-white w-full focus:border-indigo duration-150 outline-none text-left border-dashed border-2 border-indigo/20"
            @click="attachments && attachments.length > 5 ? undefined : fileInput.click()"
    >
      <div>Add up to 5 files (50MB per file)</div>
    </button>
    <div v-if="attachments && attachments.length > 0"
         class="mt-[4vw] md:mt-[1vw] text-indigo text-[3.5vw] leading-[4vw] md:text-[0.875vw] md:leading-[1vw] space-y-[2vw] md:space-y-[0.5vw]"
    >
      <div v-for="attachment in attachments"
           class="rounded-[2.5vw] relative md:rounded-[0.625vw] border pl-[4vw] md:pl-[1vw] pr-[12vw] md:pr-[2.5vw] py-[3vw] md:py-[0.75vw] flex justify-between items-center"
           :class="attachment.error ? 'bg-red/5 border-red text-red' : 'bg-gray text-indigo border-indigo/10'"
           :key="attachment.file.name"
      >
        <span v-if="attachment.error">
          {{ attachment.file.name }} / Error: File too large
        </span>
        <span v-else>
          {{ attachment.file.name }}
          <div v-if="!attachment.token && !attachment.error"
               class="absolute bottom-[2vw] md:bottom-[0.5vw] w-[calc(100%-8vw)] md:w-[calc(100%-2vw)] h-[2px] bg-indigo/10"
          >
            <div class="absolute top-0 bottom-0 left-0 h-full duration-150 bg-indigo/50"
                 :style="{
                   width: `${attachment.progress}%`,
                 }"
            />
          </div>
        </span>
        <button type="button"
                class="absolute top-0 right-[1vw] md:right-[0.25vw]"
                @click="removeAttachment(attachment)"
        >
          <span class="sr-only">Remove file</span>
          <IconClose class="w-[10vw] h-[10vw] p-[2vw] md:w-[2.5vw] md:h-[2.5vw] md:p-[0.5vw]" />
        </button>
      </div>
    </div>

    <input type="file"
           class="hidden"
           @change="onFileChanged($event)"
           ref="fileInput"
           id="attachments"
           name="attachments"
           :multiple="true"
    >
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

type FileItem = {
  file: File;
  progress: number;
  error: boolean;
  token: string;
}

type SupportCardFormInputProps = {
    modelValue: string[] | null[];
}

defineProps<SupportCardFormInputProps>();

const emit = defineEmits(['update:modelValue']);
const attachments = ref<FileItem[] | null>();
const fileInput = ref<HTMLInputElement>();

async function onFileChanged(event: Event) {
  const sizeLimit = 50 * 1024 * 1024;
  const target = event.target as HTMLInputElement;

  if (target && target.files) {
    const files = Array.from(target.files).filter(file => {
      return !attachments.value?.some(item => item.file.name === file.name);
    }).map(file => {
      return {
        file,
        token: '',
        progress: 0,
        error: file.size > sizeLimit
      };
    });

    attachments.value = attachments.value ? [...attachments.value, ...files].slice(0, 5) : files.slice(0, 5);

    for (let i = 0; i < attachments.value.length; i++) {
      if (!attachments.value[i].error && !attachments.value[i].token) {
        await uploadAttachment(attachments, i);
      }
    }
  }

  if (fileInput.value?.value) fileInput.value.value = null;

  emit('update:modelValue', attachments.value?.filter(item => item.token && !item.error).map(item => item.token));
}

const uploadAttachment = async (items: any, index: number) => {
  const encodedFilename = encodeURIComponent(items.value[index].file.name);
  const file = items.value[index].file;
  const fileHeader = items.value[index].file.type;

  const response = await fetch(`https://outfit7.zendesk.com/api/v2/uploads.json?filename=${encodedFilename}`, {
    method: 'POST', 
    headers: {
      'Content-Type': fileHeader

    }, body: file
  });

  if (response.status === 201) {
    items.value[index].progress = 100;
    let responseBody = await response.json(); 
    items.value[index].token = responseBody.upload.token;
  } else {
    items.value[index].error = true;
  }
};


function removeAttachment(attachment: FileItem) {
  if (attachments.value) {
    attachments.value = attachments.value.filter(item => item !== attachment);
  }
}
</script>