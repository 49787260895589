<template>
  <div class="relative">
    <Motion v-for="character in characters"
            :initial="character.animation.initial"
            :animate="{ x: [character.animation.initial.x, 0], y: [character.animation.initial.y, 0] }"
            :transition="{ ease: [0.22, 1, 0.36, 1], ...character.animation.transition }"
            :key="character.key"
            class="absolute w-[43.5vw] h-[72.5vw]"
            :class="character.class"
    >
      <NuxtImg format="auto"
               :src="character.src"
               :alt="character.label"
               class="w-full h-full"
               :class="character.imgClass"
               sizes="xs:50vw sm:50vw md:30vw lg:30vw xl:30vw xxl:30vw"
      />
    </Motion>
  </div>
</template>
<script setup lang="ts">
import { Motion } from '@motionone/vue/dist/motion-vue.ssr.js';

const duration = 0.6;

const characters = [
  {
    key: 'angela',
    label: 'Angela',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/angela.png',
    class: 'top-[15vw] right-[-20vw]',
    imgClass: 'rotate-[-53deg]',
    animation: {
      transition :{
        duration,
        delay: 0.15
      },
      initial: {
        x: 130,
        y: 40
      }
    }
  },
  {
    key: 'ginger',
    label: 'Ginger',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/ginger.png',
    class: 'top-[-35vw] left-[28vw]',
    imgClass: 'rotate-[165deg]',
    animation: {
      transition :{
        duration,
        delay: 0.45
      },
      initial: {
        x: -10,
        y: -140
      }
    }
  },
  {
    key: 'hank',
    label: 'Hank',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/hank.png',
    class: 'bottom-[-30vw] right-[30vw]',
    imgClass: '',
    animation: {
      transition :{
        duration,
        delay: 0.3
      },
      initial: {
        x: 0,
        y: 170
      }
    }
  },
  {
    key: 'ben',
    label: 'Ben',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/ben.png',
    class: 'left-[-22vw] bottom-[0vw]',
    imgClass: 'rotate-[54deg]',
    animation: {
      transition :{
        duration,
        delay: 0.15
      },
      initial: {
        x: -135,
        y: 20
      }
    }
  },
  {
    key: 'becca',
    label: 'Becca',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/becca.png',
    class: 'right-[-13vw] bottom-[-2vw]',
    imgClass: 'rotate-[-36deg]',
    animation: {
      transition :{
        duration,
        delay: 0.45
      },
      initial: {
        x: 180,
        y: 70
      }
    }
  },
  {
    key: 'tom',
    label: 'Tom',
    src: 'https://cdn-ttf.o7web.com/assets/public/img/story/hero/tom.png',
    class: 'top-[-12vw] left-[-22vw]',
    imgClass: 'rotate-[122deg]',
    animation: {
      transition :{
        duration,
        delay: 0.3
      },
      initial: {
        x: -150,
        y: -70
      }
    }
  }
];

</script>
