<template>
  <Container class="flex items-center justify-center md:h-[calc(100vh-5vw)] relative pt-[20vw] md:pt-0 pb-[16vw] md:pb-0">
    <SvgShapeGearSharp class="hidden text-green md:w-[12.75vw] md:h-[12.75vw] absolute md:top-[35%] md:left-[-4vw]" />
    <SvgShapeHalfCircle class="hidden text-purple md:w-[12vw] absolute md:left-[13vw] md:top-[-2vw]" />
    <SvgShapeCrescent class="hidden text-turquoise md:w-[9vw] md:right-[-1vw] md:top-[3vw] absolute rotate-[-60deg]" />

    <div class="text-center">
      <span class="bg-red rounded-[1.25vw] md:rounded-[0.3125vw] md:px-[0.75vw] px-[2vw] py-[1vw] md:py-[0.375vw] text-white font-oswald md:text-[1.5vw] md:leading-[1.75vw] tracking-[-0.01em] font-medium text-[4.5vw] leading-[5.5vw] uppercase">
        Support
      </span>
      <h1 class="text-white text-[14vw] leading-[14vw] md:text-[5vw] md:leading-[5vw] font-extrabold tracking-[-0.01em] text-center md:mt-[2vw] mt-[4vw]">
        Your question is?
      </h1>
      <div class="mt-[14.5vw] md:mt-[4vw] flex flex-col md:flex-row md:justify-center space-y-[4vw] md:space-y-0 md:space-x-[2vw] text-white">
        <slot />
      </div>
    </div>
  </Container>
</template>
