<template>
  <div ref="shape">
    <svg viewBox="0 0 80 159"
         :style="{ transform: `rotate(${rotate}deg)` }"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
    ><path d="M.32 4.71v29.43a4.6 4.6 0 0 0 4.18 4.55 40.88 40.88 0 0 1 0 81.33 4.6 4.6 0 0 0-4.18 4.55v29.44a4.584 4.584 0 0 0 3.026 4.297 4.596 4.596 0 0 0 1.834.263A79.36 79.36 0 0 0 5.18.14 4.58 4.58 0 0 0 .32 4.71Z"
           fill="currentcolor"
    /></svg>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import useParallax from '@/composables/use-parallax';

type SvgShapeProps = {
  animate?: boolean,
  rotate?: number
}

const props = withDefaults(defineProps<SvgShapeProps>(), {
  animate: true,
  rotate: 0
});

const shape = ref(null);
useParallax({ element: shape, run: props.animate });
</script>