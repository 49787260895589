<template>
  <svg width="159"
       height="160"
       viewBox="0 0 632 633"
       fill="currentColor"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M631.97 316.66C631.97 293.78 621.78 273.29 605.71 259.44C615.3 240.52 616.93 217.69 608.23 196.53C599.4 175.05 581.8 159.84 561.29 153.29C563.15 131.84 555.88 109.74 539.46 93.3201C523.28 77.1401 501.59 69.86 480.43 71.42C473.83 51.26 458.85 33.97 437.73 25.16C416.3 16.22 393.1 17.9001 373.96 27.7801C360.1 11.2901 339.34 0.810059 316.12 0.810059C292.9 0.810059 272.75 11.0001 258.9 27.0701C239.98 17.4801 217.15 15.85 195.99 24.55C174.51 33.3801 159.3 50.98 152.76 71.4901C131.31 69.6301 109.21 76.9001 92.79 93.3201C76.61 109.5 69.33 131.19 70.89 152.35C50.73 158.95 33.44 173.93 24.63 195.05C15.69 216.48 17.37 239.68 27.25 258.82C10.77 272.68 0.280029 293.44 0.280029 316.66C0.280029 339.88 10.47 360.03 26.54 373.88C16.95 392.8 15.32 415.63 24.02 436.79C32.85 458.26 50.45 473.48 70.96 480.02C69.1 501.48 76.37 523.57 92.79 539.99C108.97 556.17 130.66 563.45 151.82 561.89C158.42 582.05 173.4 599.34 194.52 608.15C215.95 617.09 239.15 615.41 258.29 605.53C272.15 622.02 292.91 632.5 316.13 632.5C339.35 632.5 359.5 622.31 373.35 606.24C392.27 615.83 415.1 617.46 436.26 608.76C457.74 599.93 472.95 582.33 479.49 561.82C500.94 563.68 523.04 556.41 539.46 539.99C555.64 523.81 562.92 502.12 561.36 480.96C581.52 474.36 598.81 459.38 607.62 438.26C616.56 416.83 614.88 393.63 605 374.49C621.48 360.63 631.97 339.87 631.97 316.65V316.66Z"
          fill="currentColor"
    />
  </svg>
</template>
