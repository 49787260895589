<template>
  <svg viewBox="0 0 120 32"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M79.588 0H74.5727V28.05C79.1307 28.2068 83.6695 28.4367 88.1899 28.7381V23.7963C85.3304 23.6055 82.4634 23.4439 79.588 23.31V0ZM119.447 0.00283751H113.937L110.306 8.42033L107.043 0.00283751H101.616L107.448 15.0437L101.082 29.7994C102.843 29.9711 104.599 30.1544 106.353 30.3481L110.056 21.7687L113.723 31.2262C115.632 31.4718 117.537 31.728 119.438 31.9993L119.447 31.9961L112.913 15.1453L119.447 0.00283751ZM57.3562 27.8048L62.3718 27.8037V16.3938H69.1708V11.4519H62.3718V4.98943H71.3576V0.0030884H57.3562V27.8048ZM38.6268 4.9891H43.8761V28.0987C45.5451 28.0356 47.2175 27.9837 48.8916 27.9399V4.9891H54.1407V0.00283751H38.6268V4.9891ZM21.4798 29.535C26.1025 29.1149 30.7465 28.7687 35.4116 28.5V23.5576C32.431 23.7298 29.4588 23.9338 26.4953 24.1668V16.685C28.4398 16.6656 31.0002 16.6063 33.2685 16.6345V11.6922C31.4563 11.6878 28.5438 11.7175 26.4953 11.7437V4.98943H35.4116V0.0030884H21.4798V29.535ZM11.6413 18.1101L5.10898 0.00283751H0.342316V32C2.01094 31.7625 3.68274 31.535 5.35779 31.3168V13.9169L11.1662 30.6024C12.993 30.3906 14.8232 30.1899 16.6569 30.0013V0.00283751H11.6413V18.1101ZM92.3248 29.0357C93.9991 29.1638 95.6709 29.3013 97.341 29.4498V0.00283751H92.3248V29.0357Z"
          fill="white"
    />
    <path d="M79.588 0H74.5727V28.05C79.1307 28.2068 83.6695 28.4367 88.1899 28.7381V23.7963C85.3304 23.6055 82.4634 23.4439 79.588 23.31V0ZM119.447 0.00283751H113.937L110.306 8.42033L107.043 0.00283751H101.616L107.448 15.0437L101.082 29.7994C102.843 29.9711 104.599 30.1544 106.353 30.3481L110.056 21.7687L113.723 31.2262C115.632 31.4718 117.537 31.728 119.438 31.9993L119.447 31.9961L112.913 15.1453L119.447 0.00283751ZM57.3562 27.8048L62.3718 27.8037V16.3938H69.1708V11.4519H62.3718V4.98943H71.3576V0.0030884H57.3562V27.8048ZM38.6268 4.9891H43.8761V28.0987C45.5451 28.0356 47.2175 27.9837 48.8916 27.9399V4.9891H54.1407V0.00283751H38.6268V4.9891ZM21.4798 29.535C26.1025 29.1149 30.7465 28.7687 35.4116 28.5V23.5576C32.431 23.7298 29.4588 23.9338 26.4953 24.1668V16.685C28.4398 16.6656 31.0002 16.6063 33.2685 16.6345V11.6922C31.4563 11.6878 28.5438 11.7175 26.4953 11.7437V4.98943H35.4116V0.0030884H21.4798V29.535ZM11.6413 18.1101L5.10898 0.00283751H0.342316V32C2.01094 31.7625 3.68274 31.535 5.35779 31.3168V13.9169L11.1662 30.6024C12.993 30.3906 14.8232 30.1899 16.6569 30.0013V0.00283751H11.6413V18.1101ZM92.3248 29.0357C93.9991 29.1638 95.6709 29.3013 97.341 29.4498V0.00283751H92.3248V29.0357Z"
          fill="white"
    />
  </svg>
</template>

