<template>
  <div v-if="language"
       class="flex flex-wrap mx-[-1.5vw] md:mx-[-0.75vw] md:mb-[3vw] md:text-[1.0625vw] text-[4vw] font-work"
  >
    <NuxtLink v-for="lang in filteredLangs"
              class="block duration-100 hover:text-red py-[1.25vw] px-[1.5vw] md:py-[0.5vw] md:px-[0.75vw]"
              :class="{'text-red underline': lang === props.language}"
              :key="lang"
              :href="`${props.slug}${lang}`"
    >
      {{ getFullLanguage(lang) }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { getFullLanguage } from '@/utils/lang';

type PrivacyNavigationProps = {
  slug: string;
  language: string;
  filteredLangs: string[];
}

const props = defineProps<PrivacyNavigationProps>();

</script>