<template>
  <component :is="`Button${buttonType}`"
             :href="href"
             :size="size"
             :target="href && href.toLowerCase().startsWith('http') ? '_blank' : ''"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
/**
 * Base Button component
 *
 * This component is used to render the different button types. It is meant to be used for the
 * button types: filled, gradient, outline, link. Those components are not meant to be used
 * directly.
 *
 * The href prop is optional, since some cards are clickable, which makes the underlying cta
 * a button element, without a link. Those without a preset href, have a hrefType prop.
 */
import { computed, resolveComponent } from 'vue';
import { ButtonBaseType, CtaSize } from '../../types';

interface ButtonBaseProps {
  href?: string;
  size?: CtaSize;
  type: ButtonBaseType;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NuxtLink = resolveComponent('NuxtLink');

const props = withDefaults(defineProps<ButtonBaseProps>(), {
  label: '',
  disabled: false,
  size: 'large',
  href: ''
});

/**
 * Capitalize Button type
 */
const buttonType = computed(() => {
  return props.type.charAt(0).toUpperCase() + props.type.slice(1);
});
</script>
