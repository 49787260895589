<template>
  <div>
    <button @click="handleClick('prev')"
            class="border-white border-[1.4px] h-[3.125vw] w-[4.375vw] rounded-full mr-[0.7vw] hover:bg-white/10 transition-colors duration-300"
    >
      <IconArrowLeft class="text-white h-[1.25vw] w-[1.25vw] ml-auto mr-auto" />
    </button>
    <button @click="handleClick('next')"
            class="border-white border-[1.4px] h-[3.125vw] w-[4.375vw] rounded-full mr-[1vw] hover:bg-white/10 transition-colors duration-300"
    >
      <IconArrowRight class="text-white h-[1.25vw] w-[1.25vw] ml-auto mr-auto" />
    </button>
  </div>
</template>
<script setup lang="ts">
import { useNuxtApp } from '#imports';
const { $event } = useNuxtApp();

const emit = defineEmits(['next', 'prev']);


function handleClick(direction: any){
  $event('navigation', { props : { direction } });
  emit(direction);
}
</script>
