<template>
  <button class="self-start text-left outline-none group"
          @click="openModal()"
  >
    <div class="relative">
      <NuxtImg v-if="image"
                format="auto"
                :src="image"
                :alt="title"
                class="w-full h-[49vw] md:h-[16vw] object-cover rounded-[2.5vw] md:rounded-[1.125vw]"
                sizes="xs:85vw sm:85vw md:28vw lg:28vw xl:28vw xxl:28vw"
                loading="lazy"
      />
      <div class="absolute inset-0 flex items-center justify-center duration-150 scale-75 md:scale-100 md:opacity-0 md:group-hover:opacity-100 md:group-focus:opacity-100">
        <ButtonPlay />
      </div>
    </div>

    <div class="mt-[4vw] md:mt-[1.5vw] text-[3.25vw] leading-[4vw] flex justify-start uppercase md:text-[1vw] md:leading-[1.25vw] tracking-[0.01em] font-medium font-oswald md:mb-[1vw] mb-[3vw]">
      <div class="text-white bg-red md:px-[0.5vw] py-[1vw] px-[2vw] md:py-[0.25vw] md:rounded-l-[0.3125vw] rounded-l-[1.25vw]">
        {{ category }}
      </div>
      <div class="bg-white text-indigo md:px-[0.5vw] py-[1vw] px-[2vw] md:py-[0.25vw] md:rounded-r-[0.3125vw] rounded-r-[1.25vw]">
        {{ length }} {{ episodeText }}
      </div>
    </div>

    <h4 class="text-white font-oswald uppercase text-[6vw] md:text-[1.875vw] md:leading-[2.25vw] tracking-[-0.01em] mb-[2vw] md:mb-[0.5vw] line-clamp-2 leading-[7vw]">
      {{ title }}
    </h4>
    <p class="text-white font-work text-[4.25vw] leading-[6vw] md:text-[1.0625vw] md:leading-[1.5vw] line-clamp-3">
      {{ description }}
    </p>
  </button>
</template>
<script setup lang="ts">
import { useState, useNuxtApp } from '#imports';
import { computed } from 'vue';

type CardEpisodeProps = {
  videoPlaylistId: string;
  title: string;
  description: string;
  image: string;
  length: number | string;
  category: string;
}

const { $event } = useNuxtApp();
const props = defineProps<CardEpisodeProps>();
const isOpen = useState('video-popup', () => false);
const playlist = useState('video-playlist', () => '');

function openModal() {
  $event('card-episode-click', { props: { title: props.title, videoPlaylistId: props.videoPlaylistId } });
  isOpen.value = true;
  playlist.value = props.videoPlaylistId;
}

const episodeText = computed(() => {
  if (Number(props.length) > 1) {
    return 'Episodes';
  } else {
    return 'Episode';
  }
});

</script>
