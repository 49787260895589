<template>
  <svg width="1545"
       height="28"
       viewBox="0 0 1545 28"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.89025 26.0772C8.7688 26.0182 13.6499 25.9758 18.5288 25.8669C20.6321 25.8195 22.7277 25.7471 24.8262 25.6642C27.5835 25.5529 30.3294 25.2461 33.0922 25.4627C33.957 25.3967 34.6133 25.0669 35.0659 24.4714C35.6993 23.6868 36.1037 22.8334 36.2797 21.9093C36.5149 20.8071 36.5235 19.7006 36.3056 18.594C36.1512 18.1129 35.9969 17.6316 35.8451 17.1505C35.5479 16.3856 35.0075 15.7532 34.226 15.2534C31.4215 14.2972 28.3948 13.7852 25.3492 13.6841C23.4967 13.624 21.6518 13.5719 19.8009 13.5533C19.5198 13.5505 19.2386 13.5485 18.957 13.5473C13.9657 13.5242 8.97645 13.605 3.9871 13.6712C2.96668 13.7502 2.18889 14.1432 1.65093 14.8457C0.372874 16.1989 0.0145537 18.203 0.00181997 19.8348C-0.0237247 21.0905 0.219632 22.3158 0.732146 23.5065C1.21184 24.5078 2.20347 26.0976 3.89025 26.0772Z"
          fill="#FFD939"
    />
    <path d="M57.1473 24.9923C63.5422 25.1395 69.9345 25.2845 76.33 25.3321C81.495 25.3714 86.6814 25.3611 91.8357 25.091C92.2866 25.0561 92.6306 24.8831 92.8676 24.5719C93.4336 23.9714 93.5933 23.0865 93.5989 22.3641C93.6085 21.8079 93.5022 21.267 93.2743 20.7415C93.0435 20.2575 92.6399 19.6409 91.8786 19.6042C86.8154 19.3604 81.7548 19.098 76.6884 18.9329C70.1939 18.7224 63.6963 18.5784 57.1985 18.4343C56.6606 18.4767 56.2479 18.6844 55.9657 19.0552C55.288 19.7709 55.1006 20.83 55.0939 21.6914C55.0807 22.3557 55.2101 23.0027 55.4793 23.6324C55.7463 24.1893 56.2462 24.9709 57.1473 24.9923Z"
          fill="#FFD939"
    />
    <path d="M150.14 19.3385C147.14 19.2408 144.145 19.164 141.149 19.134C138.719 19.1096 136.289 19.1161 133.855 19.1669C128.021 19.287 122.192 19.6147 116.373 19.9466C115.754 19.9965 115.28 20.2326 114.955 20.659C114.179 21.4818 113.962 22.6982 113.954 23.6906C113.937 24.4523 114.087 25.197 114.398 25.9185C114.67 26.4901 115.308 27.5366 116.315 27.4803C122.041 27.164 127.775 26.9391 133.503 26.6519C139.029 26.3752 144.556 26.0382 150.089 25.8695C150.626 25.8272 151.037 25.6216 151.319 25.2528C151.991 24.5392 152.181 23.4843 152.188 22.6249C152.201 21.9627 152.072 21.3199 151.803 20.6923C151.533 20.129 151.041 19.3683 150.14 19.3385Z"
          fill="#FFD939"
    />
    <path d="M225.827 20.7523C226.888 19.6315 227.182 17.9756 227.193 16.6242C227.214 15.5842 227.014 14.5712 226.59 13.5849C226.358 13.213 226.124 12.839 225.892 12.4672C225.459 11.8773 224.819 11.5409 223.976 11.4576C220.815 10.9525 217.646 10.489 214.441 10.1972C212.053 9.98176 209.661 9.8613 207.263 9.83725C206.699 9.83158 206.135 9.83131 205.57 9.83639C204.102 9.84867 202.616 9.80883 201.148 9.87498C199.361 9.95668 197.573 10.0385 195.786 10.1182C193.653 10.215 191.536 10.333 189.408 10.4944C187.33 10.654 185.272 10.8972 183.253 11.315C180.994 11.7822 178.941 12.4343 176.947 13.3756C175.964 13.9782 175.281 14.7499 174.897 15.6927C173.994 17.5045 173.859 19.6852 174.263 21.595C174.621 23.2824 176.285 27.2748 179.464 25.7746C180.586 25.244 181.721 24.7803 182.941 24.4233C187.69 23.4073 192.75 23.3527 197.645 23.1126C200.629 22.966 203.613 22.8485 206.6 22.7353C209.292 22.6336 211.978 22.5193 214.66 22.3448C217.739 22.1453 220.809 21.8646 223.896 21.7232C224.74 21.659 225.385 21.3355 225.827 20.7523Z"
          fill="#FFD939"
    />
    <path d="M241.061 21.1986C247.261 20.9099 253.462 20.6607 259.667 20.4405C262.242 20.348 264.815 20.2306 267.388 20.1048C270.067 19.976 272.757 19.7891 275.442 19.8368C276.225 19.7761 276.82 19.4769 277.23 18.9371C278.213 17.8984 278.486 16.3649 278.496 15.113C278.514 14.15 278.329 13.2117 277.936 12.2984C277.722 11.9537 277.506 11.6091 277.29 11.2622C276.889 10.7162 276.298 10.4031 275.516 10.3268C272.904 9.7006 270.202 9.48457 267.502 9.2395C265.048 9.01548 262.55 8.98217 260.084 8.94091C258.666 8.91621 257.246 8.89165 255.828 8.86917C255.794 8.86855 255.759 8.86806 255.724 8.86771C254.68 8.85724 253.624 8.93554 252.583 8.96738C248.769 9.08485 244.962 9.2148 241.153 9.38841C240.183 9.46371 239.442 9.8362 238.931 10.5059C237.714 11.7934 237.372 13.6999 237.36 15.2549C237.337 16.4526 237.568 17.6154 238.054 18.7497C238.487 19.6572 239.476 21.272 241.061 21.1986Z"
          fill="#FFD939"
    />
    <path d="M332.885 12.1934C327.884 11.8402 322.882 11.4724 317.86 11.4012C311.928 11.3188 305.996 11.2428 300.065 11.1625C299.305 11.2213 298.726 11.5125 298.327 12.0378C297.375 13.0457 297.108 14.5357 297.099 15.7521C297.081 16.6883 297.261 17.5995 297.641 18.4857C298.015 19.2617 298.725 20.3795 299.993 20.4006C305.89 20.4909 311.787 20.5853 317.685 20.6589C322.741 20.7242 327.782 20.4842 332.822 20.2586C333.487 20.2071 333.992 19.9526 334.341 19.4973C335.173 18.6171 335.407 17.3136 335.415 16.2527C335.43 15.4348 335.272 14.6402 334.939 13.8666C334.588 13.1323 334.01 12.2732 332.885 12.1934Z"
          fill="#FFD939"
    />
    <path d="M408.836 16.6195C408.847 15.1435 408.406 12.3156 405.988 12.0504C399.345 11.3215 392.666 10.8082 385.963 10.6351C377.713 10.4216 369.463 10.2723 361.208 10.1211C360.232 10.1985 359.486 10.573 358.972 11.2447C357.747 12.5424 357.405 14.4595 357.393 16.0247C357.368 17.2265 357.601 18.3999 358.093 19.5404C358.573 20.5438 359.484 21.9709 361.115 22.0019C369.37 22.1594 377.618 22.2691 385.872 22.2605C392.566 22.2528 399.299 21.8845 405.917 21.1287C408.336 20.8519 408.824 18.1453 408.836 16.6195Z"
          fill="#FFD939"
    />
    <path d="M432.639 23.3699C439.709 22.8056 446.783 22.3429 453.878 22.0092C456.514 21.884 459.155 21.7818 461.789 21.6296C464.971 21.4458 468.17 21.1809 471.362 21.1797C472.393 21.1007 473.178 20.7058 473.719 19.995C475.01 18.6294 475.371 16.6088 475.384 14.9605C475.41 13.6943 475.164 12.4587 474.646 11.2576C474.363 10.802 474.082 10.3487 473.798 9.89336C473.269 9.17382 472.489 8.76333 471.459 8.66163C468.779 8.13947 466.075 8.05904 463.344 8.03165C462.915 8.02733 462.485 8.02441 462.054 8.02117C459.466 8.00155 456.853 8.18082 454.272 8.3148C447.079 8.68695 439.913 9.19015 432.745 9.78057C431.627 9.86691 430.775 10.2944 430.19 11.065C428.786 12.5478 428.395 14.7426 428.381 16.5321C428.352 17.9082 428.621 19.2478 429.18 20.5532C429.654 21.542 430.841 23.5138 432.639 23.3699Z"
          fill="#FFD939"
    />
    <path d="M496.576 10.695C493.068 10.3525 489.567 10.2053 486.036 10.1408C485.232 10.2033 484.618 10.5127 484.194 11.067C483.185 12.1364 482.901 13.7156 482.891 15.0048C482.872 15.9969 483.063 16.9622 483.469 17.9026C483.853 18.7099 484.623 19.9301 485.959 19.9331C489.491 19.9374 492.989 19.8397 496.506 19.632C497.242 19.575 497.802 19.292 498.189 18.7852C499.111 17.8103 499.367 16.368 499.376 15.1908C499.387 13.7626 498.956 10.9286 496.576 10.695Z"
          fill="#FFD939"
    />
    <path d="M529.87 24.3138C534.748 24.2549 539.63 24.2124 544.508 24.1036C546.612 24.0562 548.707 23.9838 550.806 23.9009C553.563 23.7895 556.309 23.4828 559.072 23.6994C559.937 23.6334 560.593 23.3036 561.046 22.7081C561.679 21.9235 562.083 21.0701 562.259 20.1459C562.495 19.0438 562.503 17.9373 562.285 16.8306C562.131 16.3495 561.977 15.8683 561.825 15.3872C561.528 14.6223 560.987 13.9899 560.206 13.4901C557.401 12.5338 554.375 12.0219 551.329 11.9207C549.476 11.8607 547.631 11.8086 545.781 11.79C545.499 11.7872 545.218 11.7852 544.937 11.784C539.945 11.7608 534.956 11.8417 529.967 11.9078C528.946 11.9869 528.169 12.3799 527.631 13.0824C526.353 14.4356 525.994 16.4397 525.981 18.0714C525.956 19.3271 526.199 20.5524 526.712 21.7431C527.192 22.7444 528.183 24.3343 529.87 24.3138Z"
          fill="#FFD939"
    />
    <path d="M583.127 23.229C589.522 23.3762 595.914 23.5212 602.309 23.5688C607.474 23.608 612.661 23.5978 617.815 23.3277C618.266 23.2928 618.61 23.1197 618.847 22.8086C619.413 22.2081 619.573 21.3232 619.578 20.6007C619.588 20.0445 619.481 19.5036 619.254 18.9781C619.023 18.4942 618.619 17.8776 617.858 17.8409C612.795 17.5971 607.734 17.3346 602.668 17.1696C596.173 16.9591 589.676 16.8151 583.178 16.671C582.64 16.7134 582.227 16.9211 581.945 17.2918C581.267 18.0075 581.08 19.0666 581.073 19.9281C581.06 20.5923 581.189 21.2394 581.459 21.8691C581.726 22.426 582.226 23.2075 583.127 23.229Z"
          fill="#FFD939"
    />
    <path d="M676.119 17.5751C673.12 17.4774 670.125 17.4007 667.129 17.3706C664.699 17.3462 662.268 17.3527 659.834 17.4036C654.001 17.5237 648.172 17.8513 642.353 18.1832C641.733 18.2331 641.26 18.4693 640.935 18.8956C640.159 19.7184 639.941 20.9348 639.934 21.9272C639.917 22.689 640.067 23.4337 640.378 24.1551C640.65 24.7267 641.287 25.7733 642.294 25.7169C648.021 25.4006 653.754 25.1758 659.483 24.8886C665.009 24.6119 670.535 24.2748 676.068 24.1061C676.606 24.0638 677.017 23.8582 677.299 23.4894C677.971 22.7758 678.161 21.7209 678.168 20.8615C678.181 20.1994 678.051 19.5566 677.782 18.9289C677.513 18.3656 677.02 17.605 676.119 17.5751Z"
          fill="#FFD939"
    />
    <path d="M751.807 18.989C752.867 17.8681 753.162 16.2123 753.172 14.8608C753.194 13.8209 752.994 12.8078 752.569 11.8216C752.337 11.4496 752.103 11.0756 751.871 10.7039C751.439 10.114 750.798 9.77753 749.955 9.69424C746.794 9.18917 743.625 8.72566 740.42 8.4339C738.032 8.2184 735.64 8.09795 733.242 8.0739C732.678 8.06823 732.114 8.06796 731.55 8.07304C730.082 8.08532 728.596 8.04548 727.127 8.11163C725.34 8.19333 723.552 8.27519 721.765 8.35481C719.632 8.45166 717.515 8.5696 715.387 8.73101C713.309 8.89068 711.252 9.13381 709.232 9.55165C706.973 10.0189 704.92 10.6709 702.926 11.6123C701.943 12.2148 701.26 12.9865 700.876 13.9294C699.974 15.7412 699.838 17.9218 700.242 19.8317C700.601 21.519 702.264 25.5115 705.443 24.0112C706.566 23.4806 707.7 23.017 708.92 22.6599C713.67 21.644 718.729 21.5893 723.624 21.3493C726.609 21.2027 729.593 21.0852 732.58 20.9719C735.271 20.8702 737.957 20.7559 740.639 20.5815C743.719 20.382 746.788 20.1013 749.875 19.9599C750.719 19.8957 751.365 19.5722 751.807 18.989Z"
          fill="#FFD939"
    />
    <path d="M767.04 19.4352C773.241 19.1466 779.441 18.8973 785.646 18.6772C788.222 18.5846 790.795 18.4673 793.368 18.3415C796.046 18.2126 798.736 18.0257 801.421 18.0735C802.204 18.0128 802.799 17.7136 803.21 17.1738C804.193 16.1351 804.466 14.6016 804.475 13.3497C804.493 12.3866 804.308 11.4484 803.915 10.5351C803.701 10.1904 803.485 9.84574 803.27 9.49892C802.868 8.9529 802.277 8.63981 801.495 8.56349C798.883 7.93728 796.181 7.72125 793.482 7.47618C791.027 7.25216 788.529 7.21885 786.063 7.17759C784.645 7.15289 783.225 7.12833 781.807 7.10585C781.773 7.10522 781.738 7.10474 781.704 7.10439C780.66 7.09392 779.604 7.17221 778.562 7.20406C774.748 7.32152 770.942 7.45148 767.132 7.62509C766.162 7.70039 765.421 8.07288 764.91 8.74259C763.693 10.03 763.351 11.9366 763.339 13.4916C763.316 14.6893 763.547 15.852 764.034 16.9864C764.467 17.8939 765.456 19.5087 767.04 19.4352Z"
          fill="#FFD939"
    />
    <path d="M858.865 10.43C853.863 10.0768 848.861 9.70904 843.839 9.63787C837.907 9.55552 831.976 9.47949 826.044 9.39919C825.285 9.458 824.705 9.74915 824.306 10.2745C823.355 11.2823 823.087 12.7724 823.078 13.9888C823.06 14.925 823.24 15.8362 823.621 16.7223C823.994 17.4983 824.705 18.6162 825.972 18.6373C831.869 18.7276 837.767 18.822 843.664 18.8956C848.721 18.9609 853.761 18.7208 858.802 18.4952C859.466 18.4437 859.972 18.1893 860.32 17.7339C861.152 16.8537 861.386 15.5502 861.395 14.4894C861.409 13.6715 861.252 12.8768 860.918 12.1033C860.568 11.369 859.989 10.5099 858.865 10.43Z"
          fill="#FFD939"
    />
    <path d="M934.815 14.8562C934.827 13.3802 934.385 10.5522 931.968 10.2871C925.325 9.55821 918.645 9.04488 911.942 8.87182C903.693 8.65833 895.443 8.50902 887.187 8.35773C886.212 8.43517 885.465 8.80969 884.951 9.48142C883.727 10.7791 883.385 12.6961 883.373 14.2613C883.347 15.4632 883.581 16.6366 884.072 17.7771C884.552 18.7805 885.463 20.2076 887.094 20.2386C895.35 20.3961 903.598 20.5058 911.852 20.4972C918.545 20.4895 925.279 20.1212 931.897 19.3654C934.316 19.0886 934.803 16.382 934.815 14.8562Z"
          fill="#FFD939"
    />
    <path d="M958.619 21.6066C965.688 21.0422 972.763 20.5796 979.857 20.2458C982.493 20.1207 985.135 20.0185 987.769 19.8663C990.951 19.6824 994.15 19.4175 997.341 19.4163C998.372 19.3374 999.158 18.9425 999.698 18.2317C1000.99 16.866 1001.35 14.8455 1001.36 13.1972C1001.39 11.931 1001.14 10.6953 1000.63 9.49424C1000.34 9.0387 1000.06 8.5854 999.777 8.13002C999.248 7.41048 998.468 6.99998 997.439 6.89828C994.758 6.37613 992.054 6.2957 989.324 6.2683C988.894 6.26398 988.464 6.26107 988.033 6.25783C985.445 6.23821 982.832 6.41748 980.251 6.55146C973.059 6.9236 965.892 7.42681 958.725 8.01722C957.607 8.10356 956.755 8.53104 956.169 9.30169C954.766 10.7845 954.375 12.9792 954.361 14.7687C954.331 16.1448 954.6 17.4845 955.159 18.7899C955.634 19.7787 956.82 21.7505 958.619 21.6066Z"
          fill="#FFD939"
    />
    <path d="M1022.55 8.9316C1019.05 8.58912 1015.55 8.44197 1012.02 8.37749C1011.21 8.43997 1010.6 8.74939 1010.17 9.30366C1009.16 10.3731 1008.88 11.9522 1008.87 13.2414C1008.85 14.2336 1009.04 15.1989 1009.45 16.1392C1009.83 16.9466 1010.6 18.1667 1011.94 18.1698C1015.47 18.1741 1018.97 18.0763 1022.49 17.8686C1023.22 17.8116 1023.78 17.5287 1024.17 17.0219C1025.09 16.0469 1025.35 14.6046 1025.36 13.4274C1025.37 11.9992 1024.94 9.1652 1022.55 8.9316Z"
          fill="#FFD939"
    />
    <path d="M1049.35 18.0575C1054.23 17.9985 1059.11 17.9561 1063.99 17.8472C1066.09 17.7998 1068.19 17.7274 1070.28 17.6446C1073.04 17.5332 1075.79 17.2264 1078.55 17.443C1079.41 17.377 1080.07 17.0472 1080.52 16.4517C1081.16 15.6671 1081.56 14.8137 1081.74 13.8896C1081.97 12.7874 1081.98 11.681 1081.76 10.5743C1081.61 10.0932 1081.45 9.61193 1081.3 9.13083C1081.01 8.36592 1080.46 7.73349 1079.68 7.23371C1076.88 6.27746 1073.85 5.76549 1070.81 5.66438C1068.95 5.60433 1067.11 5.55221 1065.26 5.53364C1064.98 5.53083 1064.7 5.52883 1064.41 5.52765C1059.42 5.50446 1054.43 5.58535 1049.44 5.65148C1048.42 5.73053 1047.65 6.12348 1047.11 6.82599C1045.83 8.17923 1045.47 10.1833 1045.46 11.8151C1045.43 13.0708 1045.68 14.2961 1046.19 15.4868C1046.67 16.488 1047.66 18.0779 1049.35 18.0575Z"
          fill="#FFD939"
    />
    <path d="M1102.6 16.9726C1109 17.1198 1115.39 17.2648 1121.79 17.3125C1126.95 17.3517 1132.14 17.3414 1137.29 17.0713C1137.74 17.0364 1138.09 16.8634 1138.32 16.5522C1138.89 15.9517 1139.05 15.0668 1139.06 14.3444C1139.07 13.7882 1138.96 13.2473 1138.73 12.7218C1138.5 12.2378 1138.1 11.6212 1137.34 11.5845C1132.27 11.3407 1127.21 11.0783 1122.15 10.9132C1115.65 10.7027 1109.15 10.5587 1102.66 10.4146C1102.12 10.457 1101.7 10.6647 1101.42 11.0355C1100.74 11.7512 1100.56 12.8103 1100.55 13.6718C1100.54 14.336 1100.67 14.983 1100.94 15.6127C1101.2 16.1697 1101.7 16.9512 1102.6 16.9726Z"
          fill="#FFD939"
    />
    <path d="M1195.6 11.3188C1192.6 11.2211 1189.6 11.1443 1186.61 11.1143C1184.18 11.0899 1181.75 11.0964 1179.31 11.1472C1173.48 11.2673 1167.65 11.595 1161.83 11.9269C1161.21 11.9768 1160.74 12.2129 1160.41 12.6393C1159.64 13.4621 1159.42 14.6785 1159.41 15.6709C1159.39 16.4326 1159.54 17.1773 1159.86 17.8988C1160.13 18.4704 1160.76 19.5169 1161.77 19.4606C1167.5 19.1443 1173.23 18.9194 1178.96 18.6322C1184.49 18.3555 1190.01 18.0185 1195.55 17.8498C1196.08 17.8075 1196.49 17.6019 1196.78 17.2331C1197.45 16.5195 1197.64 15.4646 1197.65 14.6052C1197.66 13.943 1197.53 13.3002 1197.26 12.6726C1196.99 12.1093 1196.5 11.3486 1195.6 11.3188Z"
          fill="#FFD939"
    />
    <path d="M1271.28 12.7326C1272.34 11.6118 1272.64 9.95595 1272.65 8.60449C1272.67 7.56455 1272.47 6.55147 1272.05 5.56521C1271.82 5.19329 1271.58 4.81928 1271.35 4.44752C1270.92 3.85764 1270.28 3.52118 1269.43 3.43789C1266.27 2.93282 1263.1 2.46931 1259.9 2.17755C1257.51 1.96206 1255.12 1.8416 1252.72 1.81755C1252.16 1.81188 1251.59 1.81161 1251.03 1.81669C1249.56 1.82897 1248.07 1.78913 1246.61 1.85528C1244.82 1.93698 1243.03 2.01885 1241.24 2.09847C1239.11 2.19531 1236.99 2.31326 1234.87 2.47466C1232.79 2.63433 1230.73 2.87746 1228.71 3.2953C1226.45 3.76253 1224.4 4.41456 1222.4 5.3559C1221.42 5.95846 1220.74 6.7302 1220.35 7.67305C1219.45 9.4848 1219.32 11.6655 1219.72 13.5754C1220.08 15.2627 1221.74 19.2551 1224.92 17.7549C1226.04 17.2243 1227.18 16.7606 1228.4 16.4036C1233.15 15.3876 1238.21 15.333 1243.1 15.0929C1246.09 14.9463 1249.07 14.8288 1252.06 14.7156C1254.75 14.6139 1257.44 14.4996 1260.12 14.3251C1263.2 14.1256 1266.27 13.8449 1269.35 13.7035C1270.2 13.6393 1270.84 13.3158 1271.28 12.7326Z"
          fill="#FFD939"
    />
    <path d="M1286.52 13.1789C1292.72 12.8902 1298.92 12.641 1305.12 12.4208C1307.7 12.3283 1310.27 12.2109 1312.85 12.0851C1315.52 11.9563 1318.21 11.7694 1320.9 11.8171C1321.68 11.7565 1322.28 11.4572 1322.69 10.9174C1323.67 9.87871 1323.94 8.3452 1323.95 7.09334C1323.97 6.13027 1323.79 5.19203 1323.39 4.27871C1323.18 3.93398 1322.96 3.58937 1322.75 3.24255C1322.35 2.69653 1321.76 2.38344 1320.97 2.30712C1318.36 1.68091 1315.66 1.46488 1312.96 1.21981C1310.51 0.99579 1308.01 0.962484 1305.54 0.921215C1304.12 0.896519 1302.7 0.871958 1301.29 0.849475C1301.25 0.848854 1301.22 0.848368 1301.18 0.848018C1300.14 0.837545 1299.08 0.915844 1298.04 0.947693C1294.23 1.06515 1290.42 1.19511 1286.61 1.36872C1285.64 1.44402 1284.9 1.81651 1284.39 2.48622C1283.17 3.77366 1282.83 5.68021 1282.82 7.2352C1282.79 8.4329 1283.03 9.59567 1283.51 10.73C1283.94 11.6375 1284.93 13.2523 1286.52 13.1789Z"
          fill="#FFD939"
    />
    <path d="M1378.34 4.17369C1373.34 3.82049 1368.34 3.45269 1363.32 3.38152C1357.39 3.29917 1351.45 3.22314 1345.52 3.14285C1344.76 3.20166 1344.18 3.4928 1343.78 4.01811C1342.83 5.02599 1342.57 6.51602 1342.56 7.73244C1342.54 8.66866 1342.72 9.57982 1343.1 10.466C1343.47 11.242 1344.18 12.3598 1345.45 12.3809C1351.35 12.4712 1357.24 12.5656 1363.14 12.6393C1368.2 12.7046 1373.24 12.4645 1378.28 12.2389C1378.94 12.1874 1379.45 11.933 1379.8 11.4776C1380.63 10.5974 1380.86 9.29389 1380.87 8.23301C1380.89 7.41515 1380.73 6.6205 1380.4 5.84695C1380.05 5.11265 1379.47 4.25358 1378.34 4.17369Z"
          fill="#FFD939"
    />
    <path d="M1454.29 8.59983C1454.3 7.12378 1453.86 4.29587 1451.45 4.03074C1444.8 3.30184 1438.12 2.78851 1431.42 2.61545C1423.17 2.40196 1414.92 2.25264 1406.67 2.10136C1405.69 2.1788 1404.94 2.55332 1404.43 3.22505C1403.2 4.52275 1402.86 6.43976 1402.85 8.00496C1402.83 9.20679 1403.06 10.3802 1403.55 11.5207C1404.03 12.5241 1404.94 13.9512 1406.57 13.9822C1414.83 14.1397 1423.08 14.2494 1431.33 14.2408C1438.02 14.2331 1444.76 13.8648 1451.37 13.109C1453.79 12.8322 1454.28 10.1256 1454.29 8.59983Z"
          fill="#FFD939"
    />
    <path d="M1478.1 15.3502C1485.17 14.7859 1492.24 14.3232 1499.33 13.9895C1501.97 13.8643 1504.61 13.7621 1507.25 13.61C1510.43 13.4261 1513.63 13.1612 1516.82 13.16C1517.85 13.081 1518.64 12.6861 1519.18 11.9753C1520.47 10.6097 1520.83 8.58911 1520.84 6.94081C1520.87 5.67464 1520.62 4.43896 1520.1 3.23789C1519.82 2.78235 1519.54 2.32905 1519.26 1.87367C1518.73 1.15413 1517.95 0.743636 1516.92 0.641936C1514.24 0.119781 1511.53 0.03935 1508.8 0.0119549C1508.37 0.0076364 1507.94 0.00472144 1507.51 0.0014826C1504.92 -0.0181394 1502.31 0.16113 1499.73 0.29511C1492.54 0.667253 1485.37 1.17046 1478.2 1.76087C1477.08 1.84722 1476.23 2.27469 1475.65 3.04534C1474.24 4.52811 1473.85 6.72286 1473.84 8.51237C1473.81 9.88848 1474.08 11.2281 1474.64 12.5335C1475.11 13.5223 1476.3 15.4941 1478.1 15.3502Z"
          fill="#FFD939"
    />
    <path d="M1542.03 2.67525C1538.52 2.33277 1535.02 2.18562 1531.49 2.12114C1530.69 2.18362 1530.07 2.49304 1529.65 3.04731C1528.64 4.11675 1528.36 5.6959 1528.35 6.9851C1528.33 7.97723 1528.52 8.94254 1528.93 9.88286C1529.31 10.6902 1530.08 11.9104 1531.42 11.9134C1534.95 11.9177 1538.45 11.82 1541.96 11.6123C1542.7 11.5553 1543.26 11.2723 1543.65 10.7655C1544.57 9.79058 1544.82 8.34827 1544.83 7.17108C1544.84 5.74286 1544.41 2.90885 1542.03 2.67525Z"
          fill="#FFD939"
    />
  </svg>
</template>
